import React, {useState, useEffect} from 'react';
import * as msal from '@azure/msal-browser';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-client';
import {Button, Col, Form} from 'react-bootstrap';
import {NoGoogleCalendar} from '../../Constants/Images';
import {errorMessage} from '../../Utils/ToastMessages';

// MSAL configuration
const msalConfig = {
  auth: {
    clientId: '9e10c793-a214-47b3-9b6a-9b0d2dd34cdb', // Replace with your client ID
    authority:
      'https://login.microsoftonline.com/common/0b74aabe-c047-48a7-948f-62e91c743e7b', // Replace with your tenant ID
    redirectUri: 'https://www.dev.roiforpros.com/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

const msalClient = new msal.PublicClientApplication(msalConfig);

// MSAL request scopes
const msalRequest = {
  scopes: [
    'openid',
    'profile',
    'user.read',
    'calendars.readwrite',
    'offline_access',
  ],
};

// Function to ensure scope in the request
function ensureScope(scope) {
  if (!msalRequest.scopes.some(s => s.toLowerCase() === scope.toLowerCase())) {
    msalRequest.scopes.push(scope);
  }
}

// Main component
const OutlookCalendar = ({
  outlookClenderData,
  deleteCalender,
  setOutLookListForModel,
  setShowCustomModel,
}) => {
  const [userName, setUserName] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const [initialized, setInitialized] = useState(false); // New state for initialization

  // Initialize MSAL
  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await msalClient.initialize();
        setInitialized(true);
      } catch (error) {
        console.error('MSAL Initialization Error:', error);
        setLoginError(error);
      }
    };
    initializeMsal();
  }, []);

  // Sign in function
  const signIn = async () => {
    if (!initialized) {
      console.error('MSAL is not initialized');
      return;
    }

    try {
      const authResult = await msalClient.loginPopup(msalRequest);
      sessionStorage.setItem('msalAccount', authResult.account.username);

      setUserName(authResult.account.username);
      const token = await getToken();
      console.log(token, 'token');
      fetchCalendars(authResult.accessToken);
      sessionStorage.setItem('token_clander', authResult.accessToken);

      setAccessToken(authResult.accessToken);
    } catch (error) {
      console.error('Login failed:', error);
      setLoginError(error);
    }
  };

  const fetchCalendars = async accessToken => {
    // const ciphertext = CryptoJS.AES.encrypt(
    //   refreshToken,
    //   ENCRYPTION_KEY,
    // ).toString();

    const token =
      'EwB4A8l6BAAUbDba3x2OMJElkF7gJ4z/VbCPEz0AAaU2k115NjAHhpj+KFhz9eQPvu/TF3siBSWCvWUzhzItDPEnJwgvs2Nbt8X9dKrjbjRATRLXbbFHYJ5FhpQ9zgR5zkMY/+7IwpRLoYZ12rG3J1FV/e1PjNCig3eDaS4t6qFkTY/s5+OQV5QCLYbGgLF1H7TPTVEyYBNOX/xukgxN/e4PnhWFOJbR+6Zbgi3f+kbWtdY/qT0DidD/SoK3U6dBlR/eUdFAS3jpceAFstP5HB3zBbFtI+WK6tmfYKo1hwJwFOBrJYjIJ577F5EEM4Wx2e8GURo47FRQnGh25BbdS2dwGKErluW66diOPMqY6Yauhu4lOq65AlPrE/mepjcQZgAAEFnkNAvXms+TzbQHwwOUusRAAiQbc/zZnRX3PnlUE7SZUu2h17qRGKp9yb8zAH82122AsU60gEsa4xZapJIspqizqRJPgn3SsgJ+MeYoe6+gf2cz50bhgpEWNIUvJVKsqycW6IpZ+hD2ndmOmGCXK4qYilykeeeuuOFtVMZTfIFO117dz6G7OXrYh+cLjkZsMDyQ5jv+R48TYvDlMjeZo2szu9efEDXPBCiCcD58w32PbMtPGpNmCutjPAnJx82JaBv/Xaqd1XpWUXCAJLVbMJKxyYf0zDlYtI9Hz9v+1Td473qMCTEzIuQxuEFchBzd1Z0olEAK79yuTvBXC3yfGGNERmth5+bhYMTXaxl8K68b29uqRbp2tRYbO2OpR4Nvn/tKzSzkwyiK6mxP8k2bRJdU0FT7ZgBk0UB4pasHghTE5KXTBGZTunGT6JU+jMSf/GotvGNc42axt4kkgUPF9QaCbgw7/aFQXw7mv18VUWJBgzxF0uNSdCHVvrpIls1Gp5rrtLMHLhYq+qOe8CMi4qN9vRyyneeumeUyspHhyY4HZJNvcEpVdhVMQc98fO9LOQ7mTWO6sD/kNhMapomSYufvc6d+8LZKjngqywOsWW6lduGzbrFUDNAHpJfbkjaM++Xy6ZmzHcaw2HUFL3IgrcJmfkBsG7MYA9aQmmqlJDxl/dsj4ikqjM9vR1TxD1KzMhnvXhOvGpzjwJofa6sniPI8M2zk1kMDauRC4/t8k7UhWPzjimIYBH1qtRYHnYHpjIYLPmYt5DVVfzcV+Ld/vnxfzJAC';
    console.log(accessToken, '-------->accessToken');
    const apiUrl = 'https://graph.microsoft.com/v1.0/me/calendars';

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetch(apiUrl, {headers})
      .then(response => response.json())
      .then(async data => {
        if (data.value.length > 0) {
          const filterData = data.value.filter(
            item =>
              !outlookClenderData.some(
                dataItem => dataItem.google_calendar_id === item.id,
              ),
          );
          if (filterData.length > 0) {
            setOutLookListForModel(filterData);
            setShowCustomModel('show-outlook-calender-model');
          } else {
            return [];
          }
        } else {
          errorMessage('No more Calendars Found');
        }
      })
      .catch(error => {
        console.log(error);
        return [];
      });
  };

  // Function to get token
  const getToken = async () => {
    let account = sessionStorage.getItem('msalAccount');
    if (!account) {
      throw new Error(
        'User info cleared from session. Please sign out and sign in again.',
      );
    }

    try {
      const silentRequest = {
        scopes: msalRequest.scopes,
        account: msalClient.getAccountByUsername(account),
      };

      const silentResult = await msalClient.acquireTokenSilent(silentRequest);
      return silentResult;
    } catch (silentError) {
      if (silentError instanceof msal.InteractionRequiredAuthError) {
        const interactiveResult = await msalClient.acquireTokenPopup(
          msalRequest,
        );
        return interactiveResult;
      } else {
        throw silentError;
      }
    }
  };

  // Microsoft Graph client setup
  const authProvider = {
    getAccessToken: async () => await getToken(),
  };

  const graphClient = MicrosoftGraph.Client.initWithMiddleware({authProvider});

  // Fetch user information
  const getUserInfo = async () => {
    ensureScope('user.read');
    try {
      const user = await graphClient.api('/me').select('id,displayName').get();
      setUserInfo(user);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  // Add event function
  const addEvent = async () => {
    const event = {
      subject: 'New Event',
      body: {
        contentType: 'HTML',
        content: 'Event Description',
      },
      start: {
        dateTime: '2024-09-01T10:00:00',
        timeZone: 'Pacific Standard Time',
      },
      end: {
        dateTime: '2024-09-01T11:00:00',
        timeZone: 'Pacific Standard Time',
      },
    };

    try {
      const response = await fetch(
        'https://graph.microsoft.com/v1.0/me/events',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(event),
        },
      );
      const result = await response.json();
      console.log('Event added:', result);
    } catch (error) {
      console.error('Error adding event:', error);
    }
  };

  // Update event function
  const updateEvent = async eventId => {
    const updatedEvent = {
      subject: 'Updated Event Subject',
      body: {
        contentType: 'HTML',
        content: 'Updated Event Description',
      },
    };

    try {
      const response = await fetch(
        `https://graph.microsoft.com/v1.0/me/events/${eventId}`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedEvent),
        },
      );
      const result = await response.json();
      console.log('Event updated:', result);
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  // Delete event function
  const deleteEvent = async eventId => {
    try {
      const response = await fetch(
        `https://graph.microsoft.com/v1.0/me/events/${eventId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      if (response.ok) {
        console.log('Event deleted successfully');
      } else {
        console.error('Error deleting event');
      }
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  useEffect(() => {
    if (userName) {
      getUserInfo();
    }
  }, [userName]);

  return (
    <div className="integration-list">
      {outlookClenderData && Object.entries(outlookClenderData).length > 0 ? (
        outlookClenderData.map((item, index) => (
          <div className="fieldset card as-link row" key={index}>
            <Col md={10} sm={10} className="p-0">
              <h2 className="title mb-1">{item.summary}</h2>
            </Col>
            <Col md={2} sm={2} className="p-0">
              <div
                className="add-icon trash-icon as-link text-right"
                onClick={() => {
                  deleteCalender(item.id);
                }}>
                <i class="fa-solid fa-trash-can"></i>
              </div>
            </Col>
          </div>
        ))
      ) : (
        <div className="no-results-wrapper no-appointments mt-5">
          <div className="img-bg-wrapper">
            <img src={NoGoogleCalendar} alt="" />
          </div>
          <div className="no-results-content">
            <p className="noRecord-title">No Calendars Added</p>
            <p className="noRecord-desc">
              Connect with your Microsoft Calendar to identify scheduling
              conflicts
            </p>

            <div className="btn-wrapper mt-3">
              <Button
                className={'btn btn-dark'}
                onClick={() => {
                  signIn();
                }}>
                Add Microsoft Calendar
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OutlookCalendar;
