import React, {useState, useEffect, useRef} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {blockInvalidChar, WithBottomClass} from '../../Utils/commonUtils';
import Scrollbars from 'react-custom-scrollbars';
import TransparentButton from '../TransparentButton/TransparentButton';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import SlidingPanel from '../SlidingPanel';
import {RoiLogo} from '../../Constants/Images';
import commonApi from '../../Services/CommonService';
import {successMessage, errorMessage} from '../../Utils/ToastMessages';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';

const CreateExpense = props => {
  const [validated, setValidated] = useState(false);
  const [expense, setExpense] = useState({
    title: '',
    cost: '',
    description: '',
  });
  const [showComponent, setShowComponent] = useState('');
  const [data, setData] = useState({});
  const buttonRef = useRef(null);

  const handle = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();

      // if (props.editExpense?.id) {
      //   editExpense();
      // } else {
      createExpense();
      // }

      setValidated(false);
    }
  };

  // const createExpense = () => {
  //   props.setExpensesList(previousTagsList => [...previousTagsList, expense]);
  //   setExpense({
  //     title: '',
  //     cost: '',
  //     description: '',
  //   });
  // };

  const createExpense = () => {
    var payLoad = {
      title: expense.title,
      cost: expense.cost,
      description: expense.description,
      ...(props.expenseItem.id && {
        expense_id : props.expenseItem.id,
      }),
    };

    commonApi
      .create_update_expense(payLoad)
      .then(res => {
        if (res.status === 200 && res.success) {
          const newData = res.data;

          if (props.expenseItem && props.expenseItem.id) {
            const updatedList = props.expensesList.map((item, index) =>
              item.id === res.data.id ? res.data : item,
            );
            props.setExpensesList(updatedList);
          }
          else{
            props.setExpensesList(previousTagsList => [
              newData,
              ...previousTagsList,
            ]);
          }

          

          successMessage(res.message);
          props.onClose();
          // setshowLoader(false);
        } else {
          errorMessage(res.message);
          // setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const deleteExpense = () => {
    const paylaod = {
      expense_id: props.expenseItem.id,
    };

    commonApi
      .delete_expenses(paylaod)
      .then(res => {
        if (res.status === 200 && res.success) {
          setShowComponent('');
          successMessage(res.message);
          props.setExpensesList(previous =>
            previous.filter(miniitem => miniitem.id !== props.expenseItem.id),
          );
          
          props.onClose();
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if(props.expenseItem && props.expenseItem.id){
      setExpense({
        title: props.expenseItem.title,
        cost: props.expenseItem.cost,
        description: props.expenseItem.description,
      });
    }
  
  }, [])
  
  const editExpense = () => {};

  const handleEditExpense = item => {
    setExpense({
      title: item.title,
      cost: item.cost,
      description: item.description,
    });
    setData({
      edit: true,
    });
  };

  const handleInputChange = e => {
    const {name, value} = e.target;
    setExpense({...expense, [name]: value});
  };

  const renderProfileTagsButtons = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
        {props.expenseItem && Object.keys(props.expenseItem).length > 0 ? (
          <span
            className=" as-link"
            onClick={() => {
              // setDeletedId(props.editTag);
              setShowComponent('show_delete_model');
            }}>
            <i className="fa-solid fa-trash-can" />
          </span>
        ) : (
          <img src={RoiLogo} alt="" />
        )}
      </>
    );
  };

  console.log(props.expensesList, 'props.expensesListprops.expensesList');

  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}
        renderData={renderProfileTagsButtons}
        showBlackHeader={true}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}>
        <div className="primary_panel_wraper  primary_panel_content">
          <div className="refferal-list-wrapper">
            <Scrollbars>
              <Form noValidate validated={validated} onSubmit={handle}>
                <div className="fix-width-forms">
                  <div className="fieldset">
                    <Form.Label controlid="validationCustom01">
                      Title
                    </Form.Label>
                    <Form.Control
                      name="title"
                      value={expense.title}
                      pattern="^\S.*$"
                      required
                      placeholder="Add expense name"
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Title is required.
                    </Form.Control.Feedback>
                  </div>

                  <div className="fieldset">
                    <Form.Label controlid="validationCustom01">Cost</Form.Label>
                    <Form.Control
                      value={expense.cost}
                      name="cost"
                      type="number"
                      onWheel={event => event.currentTarget.blur()}
                      required
                      placeholder="Add cost"
                      onKeyDown={blockInvalidChar}
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Cost is required.
                    </Form.Control.Feedback>
                  </div>

                  <div className="fieldset">
                    <Form.Label controlid="validationCustom01">
                      Description (Optional)
                    </Form.Label>
                    <Form.Control
                      name="description"
                      value={expense.description}
                      as="textarea"
                      rows={6}
                      type="text"
                      placeholder="Add description."
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Description is required.
                    </Form.Control.Feedback>
                  </div>

                  <Button
                    className="btn-dark"
                    type="submit"
                    style={{display: 'none'}}
                    ref={buttonRef}>
                    {props.expenseItem?.id
                      ? 'Update Expense'
                      : 'Create Expense'}
                  </Button>
                </div>
              </Form>
            </Scrollbars>
          </div>
          <TransparentButton
            onClick_button={() => {
              buttonRef.current.click();
            }}
            buttonName={props.expenseItem?.id ? 'Update Expense' : 'Create Expense'}
            disable={false}
            className={WithBottomClass}
          />
        </div>
      </SlidingPanel>

      {showComponent === 'show_delete_model' && props.expenseItem.id && (
        <ConfirmationModel
          closeModel={() => {
            setShowComponent('');
          }}
          discription={'Are you sure you want to delete this expense?'}
          buttonOne={'Yes'}
          buttonTwo={'No'}
          ButtonOneAction={() => {
            deleteExpense();
            // setShowComponent('');
          }}
        />
      )}
    </>
  );
};

export default CreateExpense;
