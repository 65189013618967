

export const labels = {

  login: {
    en: 'Login',
    de: 'Login de',
    fr: 'Login',
    it: 'Login',
  },
  logout: {
    en: 'Logout',
    de: 'Logout de',
    fr: 'Logout',
    it: 'Logout',
  },
  gallery_type_snapshot: {
    en: 'Snapshot',
    de: 'Snapshot de',
    fr: 'Snapshot',
    it: 'Snapshot',
  },
  gallery_type_upcoming: {
    en: 'Upcoming',
    de: 'Upcoming',
    fr: 'Upcoming',
    it: 'Upcoming',
  },
  gallery_type_opportunities: {
    en: 'Opportunities',
    de: 'Opportunities',
    fr: 'Opportunities',
    it: 'Opportunities',
  },
  gallery_type_qa: {
    en: 'Q&A',
    de: 'Q&A',
    fr: 'Q&A',
    it: 'Q&A',
  },
  show_more: {
    en: 'Show more',
    de: 'Show more',
    fr: 'Show more',
    it: 'Show more',
  },
  show_less: {
    en: 'Show less',
    de: 'Show less',
    fr: 'Show less',
    it: 'Show less',
  },

  view_more: {
    en: 'View More',
    de: 'View More',
    fr: 'View More',
    it: 'View More',
  },
  view_less: {
    en: 'View Less',
    de: 'View Less',
    fr: 'View Less',
    it: 'View Less',
  },
  read_more: {
    en: 'Read more ',
    de: 'Read more ',
    fr: 'Read more ',
    it: 'Read more ',
  },
  read_less: {
    en: 'Read less',
    de: 'Read less',
    fr: 'Read less',
    it: 'Read less',
  },
  donation_intro_text: {
    en: 'If you had a great experience with [user_name], show your appreciation through a cup of coffee.',
    de: 'If you had a great experience with [user_name], show your appreciation through a cup of coffee.',
    fr: 'If you had a great experience with [user_name], show your appreciation through a cup of coffee.',
    it: 'If you had a great experience with [user_name], show your appreciation through a cup of coffee.',
  },
  cup_of_coffee: {
    en: 'Cup(s) of coffee',
    de: 'Cup(s) of coffee',
    fr: 'Cup(s) of coffee',
    it: 'Cup(s) of coffee',
  },
  pay_text: {
    en: 'Pay',
    de: 'Pay',
    fr: 'Pay',
    it: 'Pay',
  },
  pay_with_card: {
    en: 'Pay with Credit Card',
    de: 'Pay with Credit Card',
    fr: 'Pay with Credit Card',
    it: 'Pay with Credit Card',
  },
  sessions_type_general: {
    en: 'General',
    de: 'General',
    fr: 'General',
    it: 'General',
  },
  pipeline_roles: {
    en: 'Roles',
    de: 'Roles',
    fr: 'Roles',
    it: 'Roles',
  },
  traction: {
    en: 'Traction',
    de: 'Traction',
    fr: 'Traction',
    it: 'Traction',
  },
  Tracking: {
    en: 'Tracking',
    de: 'Tracking',
    fr: 'Tracking',
    it: 'Tracking',
  },
  message_type_convos: {
    en: 'Convos',
    de: 'Convos',
    fr: 'Convos',
    it: 'Convos',
  },

  pending: {
    en: 'Pending',
    de: 'Pending',
    fr: 'Pending',
    it: 'Pending',
  },

  paid: {
    en: 'Paid',
    de: 'Paid',
    fr: 'Paid',
    it: 'Paid',
  },

  technician_payment_invoices: {
    en: 'Invoices',
    de: 'Invoices',
    fr: 'Invoices',
    it: 'Invoices',
  },

  leads: {
    en: 'Leads',
    de: 'Leads',
    fr: 'Leads',
    it: 'Leads',
  },

  propspects: {
    en: 'Propspects',
    de: 'Propspects',
    fr: 'Propspects',
    it: 'Propspects',
  },

  technician_payment_estimates: {
    en: 'Estimates',
    de: 'Estimates',
    fr: 'Estimates',
    it: 'Estimates',
  },

  technician_incomplete_tasks: {
    en: 'Incomplete',
    de: 'Incomplete',
    fr: 'Incomplete',
    it: 'Incomplete',
  },

  technician_complete_tasks: {
    en: 'Complete',
    de: 'Complete',
    fr: 'Complete',
    it: 'Complete',
  },

  details: {
    en: 'Details',
    de: 'Details',
    fr: 'Details',
    it: 'Details',
  },

  equipment: {
    en: 'Equipment',
    de: 'Equipment',
    fr: 'Equipment',
    it: 'Equipment',
  },

  ba: {
    en: 'B/A',
    de: 'B/A',
    fr: 'B/A',
    it: 'B/A',
  },

  reviews: {
    en: 'Reviews',
    de: 'Reviews',
    fr: 'Reviews',
    it: 'Reviews',
  },

  sales_cart_catalog: {
    en: 'Catalog',
    de: 'Catalog',
    fr: 'Catalog',
    it: 'Catalog',
  },

  sales_cart_sold: {
    en: 'Sold',
    de: 'Sold',
    fr: 'Sold',
    it: 'Sold',
  },

  sales_cart_inventory: {
    en: 'Inventory',
    de: 'Inventory',
    fr: 'Inventory',
    it: 'Inventory',
  },
  sessions_type_invites: {
    en: 'Invites',
    de: 'Invites',
    fr: 'Invites',
    it: 'Invites',
  },

  livechat_type_queue: {
    en: 'Queue',
    de: 'Queue',
    fr: 'Queue',
    it: 'Queue',
  },
  livechat_type_active: {
    en: 'Active',
    de: 'Active',
    fr: 'Active',
    it: 'Active',
  },

  livechat_type_closed: {
    en: 'Closed',
    de: 'Closed',
    fr: 'Closed',
    it: 'Closed',
  },
  sessions_type_happening: {
    en: 'Happening Now',
    de: 'Happening Now',
    fr: 'Happening Now',
    it: 'Happening Now',
  },
  sessions_type_upcoming: {
    en: 'Upcoming',
    de: 'Upcoming',
    fr: 'Upcoming',
    it: 'Upcoming',
  },
  opportunity_type_available: {
    en: 'Available Opportunities',
    de: 'Available Opportunities',
    fr: 'Available Opportunities',
    it: 'Available Opportunities',
  },
  opportunity_type_pending: {
    en: 'Pending',
    de: 'Pending',
    fr: 'Pending',
    it: 'Pending',
  },
  creator_text: {
    en: 'Creator',
    de: 'Creator',
    fr: 'Creator',
    it: 'Creator',
  },
  moderator_text: {
    en: 'Moderator',
    de: 'Moderator',
    fr: 'Moderator',
    it: 'Moderator',
  },
  audience_text: {
    en: 'Audience',
    de: 'Audience',
    fr: 'Audience',
    it: 'Audience',
  },
  app_link_text: {
    en: 'App Link',
    de: 'App Link',
    fr: 'App Link',
    it: 'App Link',
  },
  add_link_text: {
    en: 'Add Link',
    de: 'Add Link',
    fr: 'Add Link',
    it: 'Add Link',
  },
  website_text: {
    en: 's website',
    de: 's website',
    fr: 'le site Web de',
    it: 'il sito web di',
  },
  stage_text: {
    en: 'Stage',
    de: 'Stage',
    fr: 'Stage',
    it: 'Stage',
  },
  partnership_text: {
    en: 'Partnership',
    de: 'Partnership',
    fr: 'Partnership',
    it: 'Partnership',
  },
  circles_tab_text: {
    en: 'Circles',
    de: 'Circles',
    fr: 'Circles',
    it: 'Circles',
  },
  search_tab_text: {
    en: 'Search',
    de: 'Search',
    fr: 'Search',
    it: 'Search',
  },

  added_tab_text: {
    en: 'Added',
    de: 'Added',
    fr: 'Added',
    it: 'Added',
  },
  no_record_found: {
    en: 'No record found',
    de: 'No record found',
    fr: 'No record found',
    it: 'No record found',
  },
  no_user_found: {
    en: 'No user found',
    de: 'No user found',
    fr: 'No user found',
    it: 'No user found',
  },
  copy_link: {
    en: 'Link copied successfully',
    de: 'Link copied successfully',
    fr: 'Link copied successfully',
    it: 'Link copied successfully',
  },

  // Widget Page
  oneOnone_tab_text: {
    en: '1 on 1',
    de: '1 on 1',
    fr: '1 on 1',
    it: '1 on 1',
  },
  contacts_tab_text: {
    en: 'Contacts',
    de: 'Contacts',
    fr: 'Contacts',
    it: 'Contacts',
  },
  projects_tab_text: {
    en: 'Projects',
    de: 'Projects',
    fr: 'Projects',
    it: 'Projects',
  },

  sessions_tab_text: {
    en: 'Sessions',
    de: 'Sessions',
    fr: 'Sessions',
    it: 'Sessions',
  },
  events_tab_text: {
    en: 'Events',
    de: 'Events',
    fr: 'Events',
    it: 'Events',
  },
  opportunities_tab_text: {
    en: 'Opportunities',
    de: 'Opportunities',
    fr: 'Opportunities',
    it: 'Opportunities',
  },
  profile_tab_text: {
    en: 'Profile',
    de: 'Profile',
    fr: 'Profile',
    it: 'Profile',
  },
  snapshots_tab_text: {
    en: 'Snapshots',
    de: 'Snapshots',
    fr: 'Snapshots',
    it: 'Snapshots',
  },
  tipjar_tab_text: {
    en: 'Tip Jar',
    de: 'Tip Jar',
    fr: 'Tip Jar',
    it: 'Tip Jar',
  },

  // Login
  letsStartPhone: {
    en: 'Let’s start with your phone number',
    de: 'Let’s start with your phone number',
    fr: 'Let’s start with your phone number',
    it: 'Let’s start with your phone number',
  },
  loginInfoText: {
    en: '*Your phone number <b>will not</b> be shared with the public.',
    de: '*Your phone number <b>will not</b> be shared with the public.',
    fr: '*Your phone number <b>will not</b> be shared with the public.',
    it: '*Your phone number <b>will not</b> be shared with the public.',
  },
  sendVerificationCode: {
    en: 'Send verification code',
    de: 'Send verification code',
    fr: 'Send verification code',
    it: 'Send verification code',
  },

  verifyPhoneNo: {
    en: 'Next',
    de: 'Next',
    fr: 'Next',
    it: 'Next',
  },
  nominatedBy: {
    en: 'Nominated By',
    de: 'Nominated By',
    fr: 'Nominated By',
    it: 'Nominated By',
  },
  enterCode: {
    en: 'Enter code',
    de: 'Enter code',
    fr: 'Enter code',
    it: 'Enter code',
  },
  didntReceiveCode: {
    en: 'I didn’t receive a code',
    de: 'I didn’t receive a code',
    fr: 'I didn’t receive a code',
    it: 'I didn’t receive a code',
  },
  aboutme: {
    en: 'About Me',
    de: 'About Me',
    fr: 'About Me',
    it: 'About Me',
  },
  others: {
    en: 'Others',
    de: 'Others',
    fr: 'Others',
    it: 'Others',
  },

  // Appointment
  avalability: {
    en: 'Availability',
    de: 'Availability',
    fr: 'Availability',
    it: 'Availability',
  },
  appointment_type: {
    en: 'Schedule Type',
    de: 'Schedule Type',
    fr: 'Schedule Type',
    it: 'Schedule Type',
  },
  title: {
    en: 'Title',
    de: 'Title',
    fr: 'Title',
    it: 'Title',
  },
  duration: {
    en: 'Duration',
    de: 'Duration',
    fr: 'Duration',
    it: 'Duration',
  },
  cost: {
    en: 'Cost',
    de: 'Cost',
    fr: 'Cost',
    it: 'Cost',
  },
  optional: {
    en: 'Optional',
    de: 'Optional',
    fr: 'Optional',
    it: 'Optional',
  },
  description: {
    en: 'Description',
    de: 'Description',
    fr: 'Description',
    it: 'Description',
  },
  setup_schedule: {
    en: 'Setup Schedule',
    de: 'Setup Schedule',
    fr: 'Setup Schedule',
    it: 'Setup Schedule',
  },
  unavailable_dates: {
    en: 'Unavailable Dates',
    de: 'Unavailable Dates',
    fr: 'Unavailable Dates',
    it: 'Unavailable Dates',
  },
  open_time: {
    en: 'Open Time',
    de: 'Open Time',
    fr: 'Open Time',
    it: 'Open Time',
  },
  close_time: {
    en: 'Close Time',
    de: 'Close Time',
    fr: 'Close Time',
    it: 'Close Time',
  },
  submit: {
    en: 'Submit',
    de: 'Submit',
    fr: 'Submit',
    it: 'Submit',
  },
  funds: {
    en: 'Funds',
    de: 'Funds',
    fr: 'Funds',
    it: 'Funds',
  },
  errorMessage_for_uninvite_User: {
    en: 'You do not have access',
    de: 'You do not have access',
    fr: 'You do not have access',
    it: 'You do not have access',
  },
  no_offering_found: {
    en: 'No offering found',
    de: 'No offering found',
    fr: 'No offering found',
    it: 'No offering found',
  },

  // -----------------rehman's below----------------

  Meetup_InPerson: {
    en: 'Meetup In-Person',
    de: 'Meetup In-Person',
    fr: 'Meetup In-Person',
    it: 'Meetup In-Person',
  },
  Connect_Network: {
    en: 'Connect with your network in-person',
    de: 'Connect with your network in-person',
    fr: 'Connect with your network in-person',
    it: 'Connect with your network in-person',
  },
  Share_Opportunity: {
    en: 'Share an Recruitment',
    de: 'Share an Recruitment',
    fr: 'Share an Recruitment',
    it: 'Share an Recruitment',
  },
  Someone_Waiting: {
    en: 'Someone is waiting for your request',
    de: 'Someone is waiting for your request',
    fr: 'Someone is waiting for your request',
    it: 'Someone is waiting for your request',
  },
  Create_Opportunity: {
    en: 'Create Recruitment',
    de: 'Create Recruitment',
    fr: 'Create Recruitment',
    it: 'Create Recruitment',
  },
  Start_GroupChat: {
    en: 'Start a Group Chat',
    de: 'Start a Group Chat',
    fr: 'Start a Group Chat',
    it: 'Start a Group Chat',
  },
  Create_to_Invite: {
    en: 'Create a group chat to stay connected',
    de: 'Create a group chat to stay connected',
    fr: 'Create a group chat to stay connected',
    it: 'Create a group chat to stay connected',
  },
  Create_Table: {
    en: 'Create Group Chat',
    de: 'Create Group Chat',
    fr: 'Create Group Chat',
    it: 'Create Group Chat',
  },
  Explore_Chat: {
    en: 'Explore Chat',
    de: 'Explore Chat',
    fr: 'Explore Chat',
    it: 'Explore Chat',
  },
  Notifications_Pending: {
    en: 'No Notifications Pending',
    de: 'No Notifications Pending',
    fr: 'No Notifications Pending',
    it: 'No Notifications Pending',
  },
  NoEvent_Create_Own: {
    en: 'There are no events happening at the moment. Why not create your own?',
    de: 'There are no events happening at the moment. Why not create your own?',
    fr: 'There are no events happening at the moment. Why not create your own?',
    it: 'There are no events happening at the moment. Why not create your own?',
  },
  Actions_Pending: {
    en: 'No Tasks Pending',
    de: 'No Tasks Pending',
    fr: 'No Tasks Pending',
    it: 'No Tasks Pending',
  },
  NoAction_Desc: {
    en: 'There are no tasks for you to take at the moment',
    de: 'There are no tasks for you to take at the moment',
    fr: 'There are no tasks for you to take at the moment',
    it: 'There are no tasks for you to take at the moment',
  },
  Create_Action: {
    en: 'Create Action',
    de: 'Create Action',
    fr: 'Create Action',
    it: 'Create Action',
  },
  ELEMENT: {
    en: 'Element',
    de: 'Element',
    fr: 'Element',
    it: 'Element',
  },
  PAGE: {
    en: 'Page',
    de: 'Page',
    fr: 'Page',
    it: 'Page',
  },
  MESSAGES: {
    en: 'Messages',
    de: 'Messages',
    fr: 'Messages',
    it: 'Messages',
  },
  NOTIFICATIONS: {
    en: 'Notification',
    de: 'Notification',
    fr: 'Notification',
    it: 'Notification',
  },
  ACTIONS: {
    en: 'Task',
    de: 'Task',
    fr: 'Task',
    it: 'Task',
  },

  LIST: {
    en: 'List',
    de: 'List',
    fr: 'List',
    it: 'List',
  },

  BOARD: {
    en: 'Board',
    de: 'Board',
    fr: 'Board',
    it: 'Board',
  },

  CALENDAR: {
    en: 'Calendar',
    de: 'Calendar',
    fr: 'Calendar',
    it: 'Calendar',
  },

  UPCOMING_APPOINTMENT: {
    en: 'Upcoming Appointments',
    de: 'Upcoming Appointments',
    fr: 'Upcoming Appointments',
    it: 'Upcoming Appointments',
  },
  BOOK_APPOINTMENT: {
    en: 'Book Appointments',
    de: 'Book Appointments',
    fr: 'Book Appointments',
    it: 'Book Appointments',
  },

  CHECKLISTS: {
    en: 'Checklists',
    de: 'Checklists',
    fr: 'Checklists',
    it: 'Checklists',
  },

  my_template: {
    en: 'My Template',
    de: 'My Template',
    fr: 'My Template',
    it: 'My Template',
  },

  exchange: {
    en: 'Exchange',
    de: 'Exchange',
    fr: 'Exchange',
    it: 'Exchange',
  },

  training: {
    en: 'Training',
    de: 'Training',
    fr: 'Training',
    it: 'Training',
  },
  NOTES: {
    en: 'Notes',
    de: 'Notes',
    fr: 'Notes',
    it: 'Notes',
  },
  FIELDS: {
    en: 'Fields',
    de: 'Fields',
    fr: 'Fields',
    it: 'Fields',
  },
  SHARED: {
    en: 'Shared',
    de: 'Shared',
    fr: 'Shared',
    it: 'Shared',
  },
  WATCHING: {
    en: 'Watching',
    de: 'Watching',
    fr: 'Watching',
    it: 'Watching',
  },
  Mine: {
    en: 'Mine',
    de: 'Mine',
    fr: 'Mine',
    it: 'Mine',
  },
  NoContactsDesc: {
    en: 'Keep others top of mind and you will become top of mind. Meet new people and share sessions, events, opportunities to jumpstart your referrals.',
    de: 'Keep others top of mind and you will become top of mind. Meet new people and share sessions, events, opportunities to jumpstart your referrals.',
    fr: 'Keep others top of mind and you will become top of mind. Meet new people and share sessions, events, opportunities to jumpstart your referrals.',
    it: 'Keep others top of mind and you will become top of mind. Meet new people and share sessions, events, opportunities to jumpstart your referrals.',
  },
  NoContactsTitle: {
    en: 'Relationships Equal Referrals',
    de: 'Relationships Equal Referrals',
    fr: 'Relationships Equal Referrals',
    it: 'Relationships Equal Referrals',
  },
  DISCOVER_BUILDERS: {
    en: 'Discover Builders',
    de: 'Discover Builders',
    fr: 'Discover Builders',
    it: 'Discover Builders',
  },
  MANAGE_EVENTS: {
    en: 'Manage Meetups',
    de: 'Manage Meetups',
    fr: 'Manage Meetups',
    it: 'Manage Meetups',
  },
  MANAGE_GROUP_CHAT: {
    en: 'Manage Group Chat',
    de: 'Manage Group Chat',
    fr: 'Manage Group Chat',
    it: 'Manage Group Chat',
  },
  NO_INVITES_PENDING: {
    en: 'No Invites Pending',
    de: 'No Invites Pending',
    fr: 'No Invites Pending',
    it: 'No Invites Pending',
  },

  NO_REQUEST_PENDING: {
    en: 'No Request Pending',
    de: 'No Request Pending',
    fr: 'No Request Pending',
    it: 'No Request Pending',
  },
  NO_INVITES_PENDING_INFO: {
    en: 'There are no pending requests for you to join a table or group conversation',
    de: 'There are no pending requests for you to join a table or group conversation',
    fr: 'There are no pending requests for you to join a table or group conversation',
    it: 'There are no pending requests for you to join a table or group conversation',
  },
  SHARE_SNAPSHOTS: {
    en: 'Share Credentials',
    de: 'Share Credentials',
    fr: 'Share Credentials',
    it: 'Share Credentials',
  },
  ADD_SHAREABLES: {
    en: 'Add Shareables',
    de: 'Add Shareables',
    fr: 'Add Shareables',
    it: 'Add Shareables',
  },
  CAMERA: {
    en: 'Camera',
    de: 'Camera',
    fr: 'Camera',
    it: 'Camera',
  },
  ADD_IMAGE: {
    en: 'Add Image',
    de: 'Add Image',
    fr: 'Add Image',
    it: 'Add Image',
  },
  ADD_VIDEO: {
    en: 'Add Video',
    de: 'Add Video',
    fr: 'Add Video',
    it: 'Add Video',
  },
  GPT_TITLE_INFO: {
    en: 'Get immediate insight involving your network and interactions through text prompts',
    de: 'Get immediate insight involving your network and interactions through text prompts',
    fr: 'Get immediate insight involving your network and interactions through text prompts',
    it: 'Get immediate insight involving your network and interactions through text prompts',
  },
  DIDNOT_FIND_NETWORK: {
    en: 'We did not find anyone in your network.',
    de: 'We did not find anyone in your network.',
    fr: 'We did not find anyone in your network.',
    it: 'We did not find anyone in your network.',
  },

  team: {
    en: 'Team',
    de: 'Team',
    fr: 'Team',
    it: 'Team',
  },
  customers: {
    en: 'Customers',
    de: 'Customers',
    fr: 'Customers',
    it: 'Customers',
  },
  affliates: {
    en: 'Affliates',
    de: 'Affliates',
    fr: 'Affliates',
    it: 'Affliates',
  },

  // Roi

  qr_type_business: {
    en: 'Business',
    de: 'Business',
    fr: 'Business',
    it: 'Business',
  },

  qr_type_personal: {
    en: 'Personal',
    de: 'Personal',
    fr: 'Personal',
    it: 'Personal',
  },

  before: {
    en: 'Before',
    de: 'Before',
    fr: 'Before',
    it: 'Before',
  },

  after: {
    en: 'After',
    de: 'After',
    fr: 'After',
    it: 'After',
  },

  type_individual: {
    en: 'Individual',
    de: 'Individual',
    fr: 'Individual',
    it: 'Individual',
  },

  business_type_internal: {
    en: 'Internal',
    de: 'Internal',
    fr: 'Internal',
    it: 'Internal',
  },

  business_type_market: {
    en: 'Market',
    de: 'Market',
    fr: 'Market',
    it: 'Market',
  },

  business_type_messages: {
    en: 'Convos',
    de: 'Convos',
    fr: 'Convos',
    it: 'Convos',
  },
  business_type_LiveChat: {
    en: 'Live Chat',
    de: 'Live Chat',
    fr: 'Live Chat',
    it: 'Live Chat',
  },

  ASSIGNEE: {
    en: 'Assignee',
    de: 'Assignee',
    fr: 'Assignee',
    it: 'Assignee',
  },
  ADVANCE: {
    en: 'Advanced',
    de: 'Advanced',
    fr: 'Advanced',
    it: 'Advanced',
  },

  Retail: {en: 'Retail', de: 'Retail', fr: 'Retail', it: 'Retail'},
  Home: {en: 'Home', de: 'Home', fr: 'Home', it: 'Home'},
  Meetups: {en: 'Meetups', de: 'Meetups', fr: 'Meetups', it: 'Meetups'},
  Call_Center: {
    en: 'Call Center',
    de: 'Call Center',
    fr: 'Call Center',
    it: 'Call Center',
  },
  Messages: {en: 'Messages', de: 'Messages', fr: 'Messages', it: 'Messages'},
  Business_Profile: {
    en: 'Business Profile',
    de: 'Business Profile',
    fr: 'Business Profile',
    it: 'Business Profile',
  },
  Appointments: {
    en: 'Appointments',
    de: 'Appointments',
    fr: 'Appointments',
    it: 'Appointments',
  },
  Recruitment: {
    en: 'Recruitment',
    de: 'Recruitment',
    fr: 'Recruitment',
    it: 'Recruitment',
  },
  Share: {en: 'Share', de: 'Share', fr: 'Share', it: 'Share'},
  Circles: {en: 'Circles', de: 'Circles', fr: 'Circles', it: 'Circles'},
  Scheduler: {
    en: 'Scheduler',
    de: 'Scheduler',
    fr: 'Scheduler',
    it: 'Scheduler',
  },
  Catalog: {en: 'Offerings', de: 'Offerings', fr: 'Offerings', it: 'Offerings'},
  Tasks: {en: 'Tasks', de: 'Tasks', fr: 'Tasks', it: 'Tasks'},
  Analytics: {
    en: 'Analytics',
    de: 'Analytics',
    fr: 'Analytics',
    it: 'Analytics',
  },
  Customers: {
    en: 'Contacts',
    de: 'Contacts',
    fr: 'Contacts',
    it: 'Contacts',
  },
  Fleet: {en: 'Fleet', de: 'Fleet', fr: 'Fleet', it: 'Fleet'},
  Payments: {en: 'Payments', de: 'Payments', fr: 'Payments', it: 'Payments'},
  Check_In: {en: 'Check In', de: 'Check In', fr: 'Check In', it: 'Check In'},
  Funnel: {en: 'Pipeline', de: 'Pipeline', fr: 'Pipeline', it: 'Pipeline'},
  Tags: {en: 'Tags', de: 'Tags', fr: 'Tags', it: 'Tags'},

  Referrals: {
    en: 'Referrals',
    de: 'Referrals',
    fr: 'Referrals',
    it: 'Referrals',
  },
  Service_Center: {
    en: 'Service Center',
    de: 'Service Center',
    fr: 'Service Center',
    it: 'Service Center',
  },
  Dispatch: {en: 'Dispatch', de: 'Dispatch', fr: 'Dispatch', it: 'Dispatch'},
  Rewards: {en: 'Rewards', de: 'Rewards', fr: 'Rewards', it: 'Rewards'},
  Document_Uploads: {
    en: 'Files',
    de: 'Files',
    fr: 'Files',
    it: 'Files',
  },
  Roadmap: {en: 'Roadmap', de: 'Roadmap', fr: 'Roadmap', it: 'Roadmap'},
  Timesheet: {
    en: 'Timesheet',
    de: 'Timesheet',
    fr: 'Timesheet',
    it: 'Timesheet',
  },
  Reputation: {
    en: 'Reputation',
    de: 'Reputation',
    fr: 'Reputation',
    it: 'Reputation',
  },
  Agreements: {
    en: 'Agreements',
    de: 'Agreements',
    fr: 'Agreements',
    it: 'Agreements',
  },
  Rideshare: {
    en: 'Rideshare',
    de: 'Rideshare',
    fr: 'Rideshare',
    it: 'Rideshare',
  },
  Team: {en: 'Team', de: 'Team', fr: 'Team', it: 'Team'},
  OKRs: {en: 'OKRs', de: 'OKRs', fr: 'OKRs', it: 'OKRs'},
  Forms: {en: 'Forms', de: 'Forms', fr: 'Forms', it: 'Forms'},
  Leaderboard: {
    en: 'Leaderboard',
    de: 'Leaderboard',
    fr: 'Leaderboard',
    it: 'Leaderboard',
  },
  Files: {en: 'Files', de: 'Files', fr: 'Files', it: 'Files'},
  Play_Center: {
    en: 'Play Center',
    de: 'Play Center',
    fr: 'Play Center',
    it: 'Play Center',
  },
  Donate: {en: 'Donate', de: 'Donate', fr: 'Donate', it: 'Donate'},
  Pickup_Drop_Off: {
    en: 'Pickup Drop Off',
    de: 'Pickup Drop Off',
    fr: 'Pickup Drop Off',
    it: 'Pickup Drop Off',
  },
  Project_Management: {
    en: 'Project Management',
    de: 'Project Management',
    fr: 'Project Management',
    it: 'Project Management',
  },
  Chat_GPT: {en: 'ROI GPT', de: 'ROI GPT', fr: 'ROI GPT', it: 'ROI GPT'},
  Ratings: {en: 'Ratings', de: 'Ratings', fr: 'Ratings', it: 'Ratings'},
  Orders: {
    en: 'Purchase Orders',
    de: 'Purchase Orders',
    fr: 'Purchase Orders',
    it: 'Purchase Orders',
  },
  Property: {en: 'Property', de: 'Property', fr: 'Property', it: 'Property'},
  Announcements: {
    en: 'Announcements',
    de: 'Announcements',
    fr: 'Announcements',
    it: 'Announcements',
  },
  Monitor: {en: 'Monitor', de: 'Monitor', fr: 'Monitor', it: 'Monitor'},
  OUT_OF_STOCK: {en: 'Out of stock.', de: 'Out of stock.', fr: 'Out of stock.', it: 'Out of stock.'},
  OUT_OF_STOCK_FOR_USERS: {en: 'Required quantity of products is not available for all users.', de: 'Required quantity of products is not available for all users.', fr: 'Required quantity of products is not available for all users.', it: 'Required quantity of products is not available for all users.'},
  QUANTITY_NOT_AVAILABLE: {en: 'Quantity not available.', de: 'Quantity not available..', fr: 'Quantity not available..', it: 'Quantity not available..'},
  CREATE_PROJECT : {en: 'Create Project', de: 'Create Project', fr: 'Create Project', it: 'Create Project'},
  TRIGGERS : {en: 'Triggers', de: 'Triggers', fr: 'Triggers', it: 'Triggers'},
  EXECUTE : {en: 'Execute', de: 'Execute', fr: 'Execute', it: 'Execute'},
  NOTIFY : {en: 'Notify', de: 'Notify', fr: 'Notify', it: 'Notify'},
  UPDATE : {en: 'Update', de: 'Update', fr: 'Update', it: 'Update'},
  RelationShip : {en: 'RelationShip', de: 'RelationShip', fr: 'RelationShip', it: 'RelationShip'},
  Title : {en: 'Title', de: 'Title', fr: 'Title', it: 'Title'},
  Name : {en: 'Name', de: 'Name', fr: 'Name', it: 'Name'},
  EmailPhone : {en: 'Phone or Email', de: 'Phone or Email', fr: 'Phone or Email', it: 'Phone or Email'},
  Event : {en: 'Event', de: 'Event', fr: 'Event', it: 'Event'},
  Note : {en: 'Note', de: 'Note', fr: 'Note', it: 'Note'},
  Meetup_Not_found : {en: 'Meetup not found', de: 'Meetup not found', fr: 'Meetup not found', it: 'Meetup not found'},
  group_chat_Not_found : {en: 'Group chat not found', de: 'Group chat not found', fr: 'Group chat not found', it: 'Group chat not found'},
  Snapshot_Not_found : {en: 'Credential not found', de: 'Credential not found', fr: 'Credential not found', it: 'Credential not found'},
  Tickets_available_only : {en: 'Tickets available only', de: 'Tickets available only', fr: 'Tickets available only', it: 'Tickets available only'},
  invoice_expenses: {
    en: 'Expenses',
    de: 'Expenses',
    fr: 'Expenses',
    it: 'Expenses',
  },
  paymentTypeError: {
    en: 'Atleast one payment method is required',
    de: 'Atleast one payment method is required',
    fr: 'Atleast one payment method is required',
    it: 'Atleast one payment method is required',
  },
  milestoneError: {
    en: 'Atleast one payment milestone is required',
    de: 'Atleast one payment milestone is required',
    fr: 'Atleast one payment milestone is required',
    it: 'Atleast one payment milestone is required',
  },
  expenseError: {
    en: 'Atleast one expense is required',
    de: 'Atleast one expense is required',
    fr: 'Atleast one expense is required',
    it: 'Atleast one expense is required',
  },
  paymentMilestoneError: {
    en: 'Sum of payment milestones must be equal to cost',
    de: 'Sum of payment milestones must be equal to cost',
    fr: 'Sum of payment milestones must be equal to cost',
    it: 'Sum of payment milestones must be equal to cost',
  },
};
