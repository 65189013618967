import React from 'react';
import {Button, Form, Row, Dropdown} from 'react-bootstrap';
import Sidebar from '../../Pages/Sidebar';

import {useState, useEffect} from 'react';
import {
  rewardStar,
} from '../../Constants/Images';

import moment from 'moment';

import {Pannels} from '../../Components';
import Scrollbars from 'react-custom-scrollbars';

import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import {
  FireIcon,
  MachineIcon,
  layers,
} from '../../Constants/Images';

import ProgressBar from 'react-bootstrap/ProgressBar';
import Slider from 'react-slick';
import CreateProject from '../../Components/Project/CreateProject';
import commonApi from '../../Services/CommonService';
import NorecordFound from '../../Components/NoRecord/NorecordFound';
import {NoProjectsFound} from '../../Utils/NoRecordConstant';
import EditProject from '../../Components/Project/EditProject';
import {truncateTitle} from '../../Utils/commonUtils';

const Projects = props => {
  const [type, setType] = useState('1');
  const [showComponent, setShowComponent] = useState('');

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const [workspaceData, setWorkspaceData] = useState([]);
  const [threeDots, setThreeDots] = useState(false);
  const [tabType, setTabType] = useState('list');
  const [selectedEvent, setSelectedEvent] = useState('');
  const [showSelectedEvent, setShowSelectedEvent] = useState(false);
  const [currentComponent, setCurrentComponent] = useState('');
  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const [projectListing, setProjectListing] = useState([]);
  const [searchQry, setSearchQry] = useState('');
  console.log(projectListing, 'projectListing');

  const [selected, setSelected] = useState('');
  const [selectedProjectData, setSelectedProjectData] = useState(null);

  
  const events = [
    {
      id: '1',
      start: '2023-12-7 00:00:00',
      end: '2023-12-14 02:00:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },
    {
      id: '2',
      start: '2023-12-14 00:00:00',
      end: '2023-12-21 02:00:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },
    {
      id: '3',
      start: '2023-12-21 00:00:00',
      end: '2023-12-28 02:00:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },
    {
      id: '4',
      start: '2023-12-01 00:00:00',
      end: '2023-12-07 02:00:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },
  ];

  useEffect(() => {
    get_projects();
  }, [searchQry]);

  const get_projects = () => {
    const payload = {
      page: 1,
      search_query: searchQry,
    };

    commonApi
      .get_projects(payload)
      .then(res => {
        if (res.status === 200) {
          setProjectListing(res?.projects);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  console.log(projectListing, 'projectListingprojectListing');

  const dropdownMenu = [
    {id: '1', name: 'Project'},
    {
      id: '2',
      name: 'Project2',
    },
    {
      id: '3',
      name: 'Projec3',
    },
    {
      id: '4',
      name: 'Project4',
    },
    {
      id: '5',
      name: 'Project5',
    },
  ];

  const getDays = () => {
    const currentDate = new Date();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();
    const lastDayOfMonth = new Date(year, month, 0);
    const days = lastDayOfMonth.getDate();

    return days;
  };

  // const generateDateSlots = () => {
  //   const startDate = new Date('2023-07-01'); // Start date
  //   const endDate = new Date('2024-01-01');
  //   const dateSlots = [];
  //   const currentDate = new Date(startDate);

  //   while (currentDate <= endDate) {
  //     const formattedDate = new Intl.DateTimeFormat('en-US', {
  //       day: 'numeric',
  //       month: 'short',
  //     }).format(currentDate);

  //     dateSlots.push(
  //       <div className="tasks-listing">
  //         <div className="date">{formattedDate}</div>
  //         <div className="activity-line"></div>
  //       </div>,
  //     );

  //     // Move to the next week
  //     currentDate.setDate(currentDate.getDate() + 7);
  //   }

  //   return dateSlots;
  // };

  const generateDateSlots = () => {
    const lines = [];
    const todayDate = new Date().getDate();
    const days = getDays() + 7;

    for (let i = 1; i <= days; i++) {
      const data = moment()
        .add(i - todayDate, 'day')
        .format('D MMM')
        .toString();

      const columnView = (
        <div className="tasks-listing">
          <div className="date">{data}</div>
          <div className="activity-line"></div>
        </div>
      );

      if (i === 1) {
        lines.push(columnView);
      } else if (i % 7 === 0) {
        lines.push(columnView);
      }
    }

    return lines;
  };

  const onBarClick = (event, offset, top) => {
    // if(!showSelectedEvent){
    const sel = {...event, top: top + 25, left: offset};
    setSelected(event.id);
    setSelectedEvent(sel);
    setShowSelectedEvent(true);
    // }
    // else if(selected == event.id){
    //   setShowSelectedEvent(false)
    //   setSelected('')
    //   setSelectedEvent('')
    // }
  };

  const renderEvents = (event, i) => {
    const packedEvents = [];
    const dateSize = 133 / 7;
    const startDate = new Date(event.start).getDate() - 1;
    const endDate = new Date(event.end).getDate() - 1;

    let width;

    if (startDate < 6) {
      width = (endDate - startDate + 1) * dateSize;
    } else {
      width = (endDate - startDate) * dateSize;
    }

    let offset = 0;
    if (startDate < 6) {
      offset = dateSize * (startDate - 1) + 20;
    } else if (startDate < 7) {
      offset = dateSize * startDate + 20;
    } else if (startDate < 13) {
      offset = dateSize * startDate + 24;
    } else if (startDate < 20) {
      offset = dateSize * startDate + 20;
    } else {
      offset = dateSize * startDate + 20;
    }

    const columnView = (
      <div
        key={`event-${i}`}
        className="project-line-bar as-link"
        onClick={() => onBarClick(event, offset, 100 + i * 35)}
        style={{
          left: offset,
          width: width,
          top: 100 + i * 35,
          backgroundColor: selected === event.id ? '#68C559' : '#AEABA0',
        }}></div>
    );

    packedEvents.push(columnView);

    return packedEvents;
  };

  const rewardSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "20px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };
  const rewardSliderData = [
    {
      name: 'On-Time',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Reviews',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },
  ];


  const handleProjects = (item, type) => {
    if (type === 'add') {
      setSelectedProjectData(item)
      setProjectListing(prevProjects => [item, ...prevProjects]);
    }
    if (type === 'edit') {
      setSelectedProjectData(item)
      setProjectListing(prevProjects => {
        const projectIndex = prevProjects.findIndex(
          project => project.id === item.id,
        );

        if (projectIndex !== -1) {
          // If an item with the same id exists, replace it
          const updatedProjects = [...prevProjects];
          updatedProjects[projectIndex] = item;
          return updatedProjects;
        } else {
          // If no matching id, add the new item
          return [item, ...prevProjects];
        }
      });
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          workspaceData={workspaceData}
          showComponent={showComponent}
        />
        {/* {showComponent === 'Event' && <SessionEvents eventButton={eventButton} />} */}

        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>

            <div className="add_border"></div>

            {tabType === 'list' && (
              <Pannels
                isFloated={isFloated}
                toggleFloatedPanel={toggleFloatedPanel}
                showHiddenPanel={showHiddenPanel}
                updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
                primary={
                  <>
                    <div className=" primary_panel_content">
                      <div className="messages-header">
                        <div className="event-tabs tabs-style-2 mb-0">
                          {' '}
                          {/* Merging */}
                          <ul className="LabelTabs">
                            <li className="ml-20 d-flex align-items-center">
                              <Button
                                className={tabType === 'list' ? 'active' : ''}
                                onClick={() => {
                                  setTabType('list');
                                }}>
                                List
                              </Button>
                            </li>

                            <li className="ml-20 d-flex align-items-center">
                              <Button
                                className={tabType === 'board' ? 'active' : ''}
                                onClick={() => {
                                  setTabType('board');
                                }}>
                                Board
                              </Button>
                            </li>
                          </ul>
                        </div>

                        <div className="search-tabs-icons">
                          <span
                            className={
                              showComponent === 'createProject'
                                ? 'icon active'
                                : 'icon'
                            }>
                            <i
                              className="icon-plus"
                              onClick={() => {
                                if (showComponent === 'createProject') {
                                  setShowComponent('');
                                } else {
                                  setShowComponent('createProject');
                                }
                              }}
                            />
                          </span>

                          <span
                            onClick={() => {
                              setThreeDots(!threeDots);
                            }}
                            className={threeDots ? 'icon active' : 'icon'}>
                            <i className="fas fa-ellipsis-vertical" />
                          </span>
                        </div>
                      </div>
                      {threeDots && (
                        <div className="person-search-wrapper mb-0 messages-users-wrapper">
                          <div className="search-form d-flex">
                            <Form.Group className="search-input">
                              <Form.Control
                                type="text"
                                placeholder="Who are you looking for?"
                                value={searchQry}
                                onChange={e => {
                                  setSearchQry(e.target.value);
                                }}
                              />
                            </Form.Group>

                            <Button>
                              <i
                                className="icon-filter-icon"
                                // onClick={() => setSearchFilterModal(true)}
                              />
                            </Button>
                          </div>
                        </div>
                      )}
                      <div className="refferal-list-wrapper">
                        <Scrollbars
                          className="custom-refferal-vertical-scrollbar"
                          autoHide>
                          <div className="project_list_view mt-3">
                            {projectListing && projectListing?.length > 0 ? (
                              projectListing.map((item, index) => {
                                return (
                                  <div
                                    onClick={() => {
                                      setShowComponent('detail_Project');
                                      setSelectedProjectData(item);
                                    }}
                                    className="project_list_card as-link"
                                    index={index}>
                                    <div className="list_card_main">
                                      <div className="project_img">
                                        <img src="" alt="" />
                                      </div>
                                      <div className="list_card_content">
                                        <div>
                                          <h2>{item.name}</h2>
                                        </div>
                                        <p
                                          style={{
                                            fontSize: '14px',
                                            color: '#7E7B70',
                                          }}>
                                          Cook Spot
                                        </p>

                                        <div className="roi-service-icons">
                                          {Object.entries(
                                            item.tag_titles,
                                          ).length > 0 &&
                                            item.tag_titles.slice(0, 6)
                                              .map((MiniItem, index) => (
                                                <div
                                                  className="service-icon-time"
                                                  key={index}>
                                                  {/* {item.icon && (
                      <img className="tags_img" src={item.icon} alt="" />
                    )} */}
                                                  {MiniItem && (
                                                    <span className="tags_title">
                                                      {truncateTitle(MiniItem, 4)}
                                                    </span>
                                                  )}
                                                </div>
                                              ))}
                                          {item.tag_titles.length >
                                            6 && (
                                            <div className="w-29">
                                              <i
                                                className="fa-solid fa-ellipsis"
                                                style={{
                                                  color: 'rgb(10, 10, 10)',
                                                  paddingTop: '15px',
                                                }}></i>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="list_card_bottom">
                                      <div className="d-flex justify-content-between">
                                        <span>Next Delivery</span>

                                        <span>10/05/24</span>
                                      </div>
                                      <div className="project_bar">
                                        <ProgressBar
                                          className="task-bar"
                                          variant="success"
                                          now={60}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <NorecordFound
                                noRecordData={NoProjectsFound}
                                buttonPermesion={true}
                                ButtonAction={() => {
                                  setShowComponent('createProject');
                                }}
                              />
                            )}
                          </div>
                        </Scrollbars>
                      </div>
                    </div>
                  </>
                }
                secondary={
                  <>
                    {showComponent === 'createProject' && (
                      <CreateProject
                        handleProjectAddEdit={(item, type) => {
                          handleProjects(item, type);
                        }}
                        onClose={() => {
                          setShowComponent('');
                          
                        }}
                        onCloseApiHit={()=>{
                          setShowComponent('');
                          setShowComponent('detail_Project')
                        }}
                       
                      />
                    )}

                    {showComponent === 'detail_Project' && (
                    //   <DetailProject
                    //     onClose={() => {
                    //       setShowComponent('');
                    //     }}
                    //     selectedProjectData={selectedProjectData}
                    //     selectedDropdown={selectedDropdown}
                    //     setOpenEdit={setOpenEdit}
                    //     setSelectedProjectData={setSelectedProjectData}
                    //   />
                    // )}

                      <EditProject
                        handleProjectAddEdit={(item, type) => {
                          handleProjects(item, type);
                        }}
                        onClose={() => {
                          setShowComponent('');
                          setSelectedProjectData(null);
                        }}
                        selectedProjectData={selectedProjectData}
                      />
                    )}
                  </>
                }
              />
            )}

            {tabType === 'board' && (
              <div style={{display: 'flex', flex: 1}}>
                <div
                  className="projects-graph-page primary_panel_content"
                  style={{padding: '25px 20px 15px'}}>
                  <div className="messages-header " style={{maxWidth: '369px'}}>
                    <div className="event-tabs tabs-style-2 mb-0">
                      {' '}
                      <ul>
                        <li>
                          <Button
                            className={tabType === 'list' ? 'active' : ''}
                            onClick={() => {
                              setTabType('list');
                            }}>
                            List
                          </Button>
                        </li>
                        <li>
                          <Button
                            className={tabType === 'board' ? 'active' : ''}
                            onClick={() => {
                              setTabType('board');
                            }}>
                            Board
                          </Button>
                        </li>
                      </ul>
                    </div>

                    <div className="search-tabs-icons">
                      <span
                        className={
                          currentComponent === 'createInvoice'
                            ? 'icon active'
                            : 'icon'
                        }>
                        <i
                          className="icon-plus"
                          onClick={() => setCurrentComponent('createInvoice')}
                        />
                      </span>
                      <span
                        onClick={() => {
                          setThreeDots(!threeDots);
                        }}
                        className={
                          threeDots ? 'icon active threeDots' : 'icon threeDots'
                        }>
                        <i className="fas fa-ellipsis-vertical" />
                      </span>
                    </div>
                  </div>

                  <div className="mt-4" style={{display: 'flex'}}>
                    <div className="dropdown-search">
                      <div className="roi-template-dropdown">
                        <div className="select-wrapper">
                          <Dropdown
                            onSelect={(eventKey, event) => {
                              setType(eventKey);
                            }}
                            drop="down">
                            <Dropdown.Toggle variant="primary">
                              {type
                                ? dropdownMenu.find(item => item.id === type)
                                    .name
                                : 'Select Role'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {dropdownMenu.map((item, index) => (
                                <Dropdown.Item
                                  key={`st-${index}`}
                                  eventKey={item.id.toString()}
                                  active={item.id === type}>
                                  {item.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>

                      <div className="input-group">
                        <Form.Control
                          type="text"
                          value={''}
                          pattern="^\S.*$"
                          required
                          placeholder="Search"
                          onChange={e => {}}
                        />
                      </div>
                    </div>

                    <div style={{flex: 2}}></div>
                  </div>

                  <div className="refferal-list-wrapper">
                    <Scrollbars
                      autoHide
                      // ref={containerRef}
                      // onMouseDown={handleMouseDown}
                      // onMouseMove={handleMouseMove}
                      // onScrollStop={handleMouseUp}
                    >
                      <div className="mt-4 project-graph">
                        <div>
                          <div style={{display: 'flex'}}>
                            {generateDateSlots()}
                          </div>
                          {/* {renderGreenLine()} */}

                          {events.map((event, i) => {
                            return renderEvents(event, i);
                          })}

                          {showSelectedEvent && (
                            <div
                              style={{
                                top: selectedEvent.top,
                                left: selectedEvent.left,
                              }}
                              className="project-task">
                              <div className="duration">
                                <span>7 July</span>

                                <span>16 Sep</span>
                              </div>

                              <div className="project-task-inner-content">
                                <div className="bar-icon">
                                  <div className="bar-main">
                                    <ProgressBar
                                      className="task-bar"
                                      variant="warning"
                                      now={75}
                                    />
                                  </div>

                                  <div className="task-layer-icon">
                                    <img src={layers} alt="" />
                                  </div>
                                </div>

                                <div className="task-detail">
                                  <span>PM Task Name</span>
                                  <span>Quick summary</span>
                                </div>

                                <div className="bottom-image-icon">
                                  <div className="task-images">
                                    <div className="img-consulting">
                                      <img
                                        src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="img-consulting">
                                      <img
                                        src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="img-consulting">
                                      <img
                                        src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                        alt=""
                                      />
                                    </div>
                                  </div>

                                  <div className="task-icons">
                                    <Row>
                                      <div className="appointment_icon1">
                                        <img
                                          className="appointment_icon1_image"
                                          src={FireIcon}
                                          alt=""
                                        />
                                      </div>
                                      <div className="appointment_icon1">
                                        <img
                                          className="appointment_icon1_image"
                                          src={MachineIcon}
                                          alt=""
                                        />
                                      </div>
                                      <div className="dots">...</div>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              </div>
            )}
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
