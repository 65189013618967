import React, {useState, useEffect} from 'react';
import {format, addMonths, subMonths} from 'date-fns';

const Calendar = ({showDate = true, showTime = true, onConfirm}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedHour, setSelectedHour] = useState(12);
  const [selectedMinute, setSelectedMinute] = useState(0);
  const [amPm, setAmPm] = useState('AM');
  const [view, setView] = useState('date'); // 'date' or 'time'

  const toggleCalendar = () => setIsOpen(!isOpen);

  const selectDate = day => {
    setSelectedDate(
      new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day),
    );
    // setIsOpen(false);
  };

  useEffect(() => {
    if (showDate) {
      setView('date');
    } else {
      setView('time');
    }
  }, []);

  const clearSelection = () => {
    setSelectedDate(new Date()); // Reset to today's date
    setSelectedHour(12);
    setSelectedMinute(0);
    setAmPm('AM');
    setIsOpen(false);
  };

  const prevMonth = () => {
    setSelectedDate(subMonths(selectedDate, 1));
  };

  const nextMonth = () => {
    setSelectedDate(addMonths(selectedDate, 1));
  };

  const handleHourChange = hour => {
    setSelectedHour(hour);
  };

  const handleMinuteChange = minute => {
    setSelectedMinute(minute);
  };

  const toggleAmPm = () => {
    setAmPm(prev => (prev === 'AM' ? 'PM' : 'AM'));
  };

  // New function for handling OK button
  const handleOkClick = () => {
    setIsOpen(false); // Close the calendar

    // (Optional) If you want to pass the selected date/time to a parent component
    if (onConfirm) {
      onConfirm({
        date: selectedDate,
        hour: selectedHour,
        minute: selectedMinute,
        period: amPm,
      });
    }

    console.log("Selected Date and Time:", {
      date: selectedDate,
      time: `${selectedHour}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`,
    });
  };

  const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const daysInMonth = Array.from(
    {
      length: new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        0,
      ).getDate(),
    },
    (_, i) => i + 1,
  );

  const hours = Array.from({length: 12}, (_, i) => i + 1);
  const minutes = Array.from({length: 60}, (_, i) => i);

  return (
    <div className="calendar-custom-relative-holder">
      <div className="relative">
        <button
          onClick={toggleCalendar}
          className="flex items-center justify-between w-full max-w-xs p-3 bg-white border rounded-md shadow-sm focus:outline-none transition duration-300 ease-in-out">
          <span className="clnd-date-time-txt">
            {showDate && format(selectedDate, 'EEE, MMM dd')}
            {showTime &&
              ` ${selectedHour}:${selectedMinute
                .toString()
                .padStart(2, '0')} ${amPm}`}
          </span>
          <svg
            className={`w-5 h-5 text-gray-600 transition-transform duration-300 ease-in-out ${
              isOpen ? 'rotate-0' : ''/*rotate 180*/
            }`}
            fill="none"
            stroke="#9b9787"
            viewBox="0 0 24 24">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M8 7V3m8 4V3m-7 8h6m-6 4h6m-8 6h10a2 2 0 002-2V7a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </button>

        <div
          className={`absolute top-14 left-0 w-80 bg-white rounded-lg shadow-lg z-10 transform transition-transform duration-300 ease-in-out ${
            isOpen
              ? 'scale-100 opacity-100'
              : 'scale-95 opacity-0 pointer-events-none'
          }`}>
          {/* Header */}
          <div className="text-white rounded-t-lg p-2 date-time-pannel-head">
            <div className="text-center text-sm uppercase">
              {/* Select {showDate && 'Date'}
              {showTime && showDate && ' and '}
              {showTime && 'Time'} */}
            </div>
            <div className="text-center font-semibold">
              {showDate && format(selectedDate, 'EEE, MMM dd')}
              {showTime &&
                ` ${selectedHour}:${selectedMinute
                  .toString()
                  .padStart(2, '0')} ${amPm}`}
            </div>
          </div>

          {showDate && showTime && (
            <div className="flex justify-center items-center p-2 bg-white border-b date-time-combn-section">
              <button
                onClick={() => setView('date')}
                className={`p-2 w-1/2 text-center font-semibold ${
                  view === 'date'
                    ? 'roi-bg-green font-semibold'
                    : 'hover:roi-bg-green'
                }`}>
                Date
              </button>
              <button
                onClick={() => setView('time')}
                className={`p-2 w-1/2 text-center font-semibold time-btn ${
                  view === 'time'
                    ? 'roi-bg-green font-semibold'
                    : 'hover:roi-bg-green'
                }`} >
                Time
              </button>
            </div>
          )}

          {view === 'date' && showDate && (
            <>
              {/* Calendar Controls */}
              <div className="flex justify-between items-center bg-white p-2 border-b full-date-section">
                <button
                  className="p-1 rounded-full hover:bg-gray-100 transition duration-300 ease-in-out"
                  onClick={prevMonth}>
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="#9b9787"
                    viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </button>
                <div className="text-lg font-medium exact-date-holder">
                  {format(selectedDate, 'MMMM yyyy')}
                </div>
                <button
                  className="p-1 rounded-full hover:bg-gray-100 transition duration-300 ease-in-out"
                  onClick={nextMonth}>
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="#9b9787"
                    viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>

              {/* Days of the Week */}
              <div className="grid grid-cols-7 text-center text-gray-500 bg-white py-2 day-name-section">
                {daysOfWeek.map(day => (
                  <div key={day} className="text-sm font-medium day-name-color">
                    {day}
                  </div>
                ))}
              </div>

              {/* Calendar Days */}
              <div className="grid grid-cols-8 text-center bg-white py-0">
                {daysInMonth.map(day => (
                  <div
                    key={day}
                    className={`round-custom-time cursor-pointer transition duration-300 ease-in-out ${
                      selectedDate.getDate() === day
                        ? 'roi-bg-green text-white'
                        : 'hover:roi-bg-green'
                    }`}
                    onClick={() => selectDate(day)}>
                    {day}
                  </div>
                ))}
              </div>
            </>
          )}

          {view === 'time' && showTime && (
            <div className="flex justify-center items-center p-4 border-t time-corner-date-time-calander">
              {/* Hour Picker */}
              <div className="flex flex-col items-center">
                <div className="text-sm text-gray-600 mb-2 font-medium clnd-hour-txt">Hour</div>
                <div
                  className="px-2 py-2 h-24 w-14 bg-gray-50 rounded-md overflow-y-auto overflow-x-hidden snap-y"
                  style={{scrollBehavior: 'smooth'}}>
                  {hours.map(hour => (
                    <div
                      key={hour}
                      className={`round-custom-time cursor-pointer p-2 text-center transition duration-300 ease-in-out snap-start ${
                        selectedHour === hour
                          ? 'roi-bg-green text-white'
                          : ''
                      }`}
                      onClick={() => handleHourChange(hour)}>
                      {hour}
                    </div>
                  ))}
                </div>
              </div>

              {/* Minute Picker */}
              <div className="flex flex-col items-center mx-4">
                <div className="text-sm text-gray-600 mb-2 font-medium clnd-hour-txt">Minute</div>
                <div
                  className="px-2 py-2 h-24 w-14 bg-gray-50 rounded-md overflow-y-auto snap-y overflow-x-hidden"
                  style={{scrollBehavior: 'smooth'}}>
                  {minutes.map(minute => (
                    <div
                      key={minute}
                      className={`round-custom-time cursor-pointer p-2 text-center transition duration-300 ease-in-out rounded-full snap-start ${
                        selectedMinute === minute
                          ? 'roi-bg-green text-white'
                          : ''
                      }`}
                      onClick={() => handleMinuteChange(minute)}>
                      {minute.toString().padStart(2, '0')}
                    </div>
                  ))}
                </div>
              </div>

              {/* AM/PM Picker */}
              <div className="flex flex-col items-center">
                <div className="text-sm text-gray-600 mb-2 font-medium clnd-hour-txt">AM/PM</div>
                <div
                  className="px-2 py-2 h-24 w-14 bg-gray-50 rounded-md overflow-y-auto snap-y overflow-x-hidden"
                  style={{scrollBehavior: 'smooth'}}>
                  {['AM', 'PM'].map(period => (
                    <div
                      key={period}
                      className={`round-custom-time cursor-pointer p-2 text-center transition duration-300 ease-in-out rounded-full snap-start ${
                        amPm === period
                          ? 'roi-bg-green text-white'
                          : ''
                      }`}
                      onClick={() => setAmPm(period)}>
                      {period}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-between p-1 bg-gray-50 rounded-b-lg ok-clear-btn-section">
            <button
              onClick={clearSelection}
              className="px-4 py-2 text-sm text-gray-600 rounded-lg transition duration-300 ease-in-out date-custom-clear-btn">
              Clear
            </button>
            <button
              onClick={handleOkClick}
              className="px-4 py-2 text-sm text-gray-600 rounded-lg transition duration-300 ease-in-out date-custom-clear-btn">
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
