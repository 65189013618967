import React, {useState, useEffect} from 'react';
import {Button, Col, Form} from 'react-bootstrap';

import {successMessage, errorMessage} from '../Utils/ToastMessages';

import commonApi from '../Services/CommonService';

import {languageArray} from '../Components/Languages/Languages';
import {CustomModal} from '../Components';

import {useDispatch, useSelector} from 'react-redux';
// import {callChatApi} from '../Store/Actions/ChatAction';
import {callDeepLink} from '../Store/Actions/DeepLinkAction';
import {SlidingPanel} from '../Components';
// import ApiCalendar from 'react-google-calendar-api';
import {useGoogleLogin} from '@react-oauth/google';
import {
  GOOGLE_AUTH_TOKEN_ENDPOINT,
  ENCRYPTION_KEY,
  SITE_URL_FOR_API,
  SITE_URL_FOR_API_Calendar,
} from '../Utils/env';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import {useHistory} from 'react-router-dom';

import {amplitudeEvent} from '../Services/AmplitudeServices';
import CreateWorkSpace from '../Components/WorkSpace/CreateWorkSpace';
import PersnalSvg from '../Svg/PersnalSvg';
import PersonalCreateModel from '../Components/Modals/PersonalCreateModel';

import {get_my_all_associate_workspaces} from '../Store/Actions/SessionAction';
import {NoEventBrite, NoGoogleCalendar} from '../Constants/Images';
import Scrollbars from 'react-custom-scrollbars';
import CustomDropdown from '../Components/CustomDropdown';
import {PublicClientApplication} from '@azure/msal-browser';

import * as msal from '@azure/msal-browser';
import OutlookCalendar from '../Components/FeedBack/OutlookCalendar';


// MSAL configuration
const msalConfig = {
  auth: {
      clientId: '9e10c793-a214-47b3-9b6a-9b0d2dd34cdb', // Replace with your client ID
      authority: 'https://login.microsoftonline.com/common/0b74aabe-c047-48a7-948f-62e91c743e7b', // Replace with your tenant ID
      redirectUri: 'https://www.dev.roiforpros.com/'
  },
  cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
  }
};

const msalClient = new msal.PublicClientApplication(msalConfig);

// MSAL request scopes
const msalRequest = { scopes: ['openid', 'profile', 'user.read', 'calendars.readwrite'] };

const Settings = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [subTabType, setSubTabsType] = useState(props.tabinformation);
  const [languages, setLanguages] = useState([]);
  const [searchLanguages, setSearchLanguages] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [showComponent, setshowComponent] = useState('');
  const [isCheck, setIsCheck] = useState('');
  const [list, setList] = useState('');
  // const [sessionCategories, setSessionCategories] = useState('');
  // const [qrImage, setQrImage] = useState('');
  const [itemType, setItemType] = useState('consult');
  // const [opportunity, setOpportunity] = useState('');
  // const [consult, setConsult] = useState('');
  const [itemTypeSe, setitemTypeSe] = useState('');
  const [uniqName, setUniqName] = useState(localStorage.getItem('userName'));
  const [email, setEmail] = useState(localStorage.getItem('email'));
  const [firstName, setFirstName] = useState(localStorage.getItem('firstName'));
  const [lastName, setLastName] = useState(localStorage.getItem('lastName'));
  // const [showLoader, setshowLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const [search, setSearch] = useState('');
  const [allCalendars, setallCalendars] = useState([]);
  // const [workSpaceItem, setWorkspaceData] = useState([]);
  const [calenderListForModel, setCalenderListForModel] = useState([]);
  const [outLookListForModel, setOutLookListForModel] = useState([]);
  const [showCustomModel, setShowCustomModel] = useState([]);
  const [workSpaceIds, setWorkSpaceIds] = useState([]);
  // const [topIcons, setTopIcons] = useState(false);
  const [calendarData, setCalendarData] = useState([]);
  const [outLookData, setOutLookData] = useState([]);
  const [modelId, setModelId] = useState([]);
  const [modelIdItem, setModelItem] = useState([]);
  const [accessToken, setAccessToken] = useState('');
  const [eventBriteList, setEventBriteList] = useState([]);

  const dropdownValues = [
    {id: 'google-calendar', name: 'Google Calendar'},
    {id: 'event', name: 'EventBrite'},
    {id: 'outlook', name: 'Outlook'},
  ];
  // const {host, pathname} = window.location;

  let accesTokenForGoogle = null;
  //  const [ consulttype  ,  setConsulttype  ] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const [isFloated, toggleFloatedPanel] = useState(false);
  const [typeofCalender, setTypeofCalender] = useState('google-calendar');
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const [userName, setUserName] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [microSoftAccessToken, setMicrosoftAccessToken] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const [initialized, setInitialized] = useState(false); // New state for initialization

  // const config = {
  //   clientId:
  //     '492426132733-rh0r6sb6shsrnt68g5pca13492hesk9m.apps.googleusercontent.com',
  //   apiKey: 'AIzaSyBSK4bytfILjWoRnK6_HTgllQdLlbQNnmU',

  //   scope: 'https://www.googleapis.com/auth/calendar',
  //   discoveryDocs: [
  //     'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  //   ],
  // };

  const listWorspacerator = useSelector(
    state => state.hitAssociateWorkSpace.data,
  );
  const personal = useSelector(state => state.hitAssociateWorkSpace.personal);

  useEffect(() => {
    dispatch(
      get_my_all_associate_workspaces({token: localStorage.getItem('token')}),
    );
    // fetchCalendars()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workSpaceIds.length > 0) {
    } else {
      // Filter the workspaces with status "active" from the API response
      const userExist = listWorspacerator.filter(
        workspace => workspace.status === 'active',
      );

      // Extract the 'id' values from 'userExist'
      const updatedIds = userExist.map(workspace => workspace.id);

      if (personal === 'yes') {
        updatedIds.push('personal'); // Add 'personal' to the ids array if needed
      }

      // setActiveWorkspacesId(updatedIds);
      setWorkSpaceIds(updatedIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listWorspacerator]);

  // const apiCalendar = new ApiCalendar(config);

  useEffect(() => {
    // getSesionCategories();
    getOpportunityType();
    getAllSessionOpportunityConsult();
    getAllLanguages();
    getRefreshToken();
    amplitudeEvent('VISIT_GENERAL_SETTINGS');
    // setTimeout(() => {
    //   getMycalenders();
    // }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getUserData();
  }, []);
  // const getMycalendersAuthenticate = async () => {
  //   console.info('> GAPI loaded----------');
  //   await apiCalendar.handleAuthClick();
  //   getMycalenders();
  // };
  // const getMycalenders = async () => {
  //   // await apiCalendar.handleAuthClick();
  //
  //
  //   setTimeout(() => {
  //     apiCalendar.listUpcomingEvents(100).then(result => {
  //
  //
  //
  //       if (result && result.result) {
  //         var data = {name: result.result.summary};
  //         setallCalendars(pre => [...pre, data]);
  //         localStorage.setItem(
  //           'ValidTime',
  //           JSON.stringify(result.result.items),
  //         );
  //       }
  //     });
  //   }, 2000);
  // };
  const makeReact = (arr, idColName, selectColName) => {
    const returnArr = [];
    arr.forEach(item => {
      if (item[selectColName] === true || item[selectColName] === 'true') {
        returnArr.push(item[idColName]);
      }
    });
    return returnArr;
  };

  useEffect(() => {
    getLinkForConsultSessionOppoertunity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemTypeSe]);
  useEffect(() => {
    get_google_calenders();
    get_eventbrite_accounts();
    get_outLook_calenders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (typeofCalender === 'event') {
      get_eventbrite_accounts();
    } else {
      if (typeofCalender === 'google-calendar') {
        get_google_calenders();
      }
      // else if(typeofCalender === 'google-calendar'){
      //   get_outLook_calenders()
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeofCalender]);

  // useEffect(() => {
  //   setitemTypeSe('');
  // }, [itemType]);
  const editUserDetailFullinfo = () => {
    // setshowLoader(true);
    var Payload = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      user_name: uniqName,
    };
    commonApi
      .edit_user_detail(Payload)
      .then(res => {
        if (res.status === 200) {
          // setshowLoader(false);
          successMessage(res.message);
          localStorage.setItem('firstName', firstName);
          localStorage.setItem('email', email);
          localStorage.setItem('userName', uniqName);
          localStorage.setItem('lastName', lastName);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // var username = localStorage.getItem('userName');
  // var type = itemTypeSe && itemTypeSe;
  // var link =
  //   'www.tryingtobuild.com/' + username + '/' + itemType + '?type=' + type;

  const editUserDetail = () => {
    // setshowLoader(true);
    var Payload = {
      user_name: uniqName,
    };
    commonApi
      .edit_user_detail(Payload)
      .then(res => {
        if (res.status === 200) {
          // setshowLoader(false);
          successMessage(res.message);
          localStorage.setItem('userName', uniqName);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  var userLanguage = window.navigator.userLanguage || window.navigator.language;

  const submitFeedback = () => {
    // setshowLoader(true);
    var payLoad = {
      feedback_description: '',
      feedback_type: '6',
    };
    commonApi
      .submit_feedback(payLoad)
      .then(res => {
        // setshowLoader(false);
        if (res.status === 200) {
          successMessage(res.message);
          localStorage.clear();
          dispatch(callDeepLink());
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getLinkForConsultSessionOppoertunity = () => {
    // setshowLoader(true);
    var Payload = {
      // item_id: itemType === "session" || itemType === "event" ? itemTypeSe : itemType === "opportunity" ? opptype:
      // itemType === "consult" ? consulttype:"",
      item_id: itemTypeSe,
      item_type: itemType,
    };
    commonApi
      .get_link_for_consult_session_opportunity(Payload)
      .then(res => {
        if (res.status === 200) {
          // setshowLoader(false);
          // setQrImage(res.data.qr_code);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const get_eventbrite_accounts = () => {
    // setshowLoader(true);
    var Payload = {
      token: localStorage.getItem('token'),
    };
    commonApi
      .get_eventbrite_accounts(Payload)
      .then(res => {
        if (res.status === 200) {
          setEventBriteList(res.account_details);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const delete_eventbrite_accounts = id => {
    // setshowLoader(true);
    var Payload = {
      account_id: id,
    };
    commonApi
      .delete_eventbrite_accounts(Payload)
      .then(res => {
        if (res.status === 200) {
          // setEventBriteList(res.account_details);
          setEventBriteList(previous =>
            previous.filter(item => item.id !== id),
          );
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getAllSessionOpportunityConsult = () => {
    commonApi
      .get_all_session_opportunity_consult()
      .then(res => {
        if (res.status === 200) {
          // setConsult(res.consult);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getOpportunityType = () => {
    // setshowLoader(true);
    commonApi
      .get_opportunity_types()
      .then(res => {
        if (res.status === 200) {
          // setshowLoader(false);
          // setOpportunity(res.opportunity_types);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const getSesionCategories = () => {
  //   setshowLoader(true);
  //   commonApi
  //     .session_categories()
  //     .then(res => {
  //       if (res.status === 200) {
  //         setshowLoader(false);
  //         setSessionCategories(res.data);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };
  const getAllLanguages = () => {
    // setshowLoader(true);
    commonApi
      .get_all_languages()
      .then(res => {
        if (res.status === 200) {
          // setshowLoader(false);
          setLanguages(res.languages);
          setSearchLanguages(res.languages);
          setList(res.languages);
          setIsCheck(makeReact(res.languages, 'id', 'selected'));
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const fName = localStorage.getItem("firstName");

  const addLanguages = () => {
    var payloadAddLanguages = {
      other_language_ids: isCheck.toString(),
      primary_language_id: localStorage.getItem('primaryLanguageId'),
    };
    commonApi
      .add_languages(payloadAddLanguages)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (list.length > 0) {
      if (isCheck.length === list.length) {
        setIsCheckAll(true);
      } else {
        setIsCheckAll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);
  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list?.map(item => item.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };
  const handleClick = id => {
    let tempArr = [...isCheck];

    if (!tempArr.includes(id)) {
      tempArr.push(id);
    } else {
      tempArr = tempArr.filter(item => item !== id);
    }

    setIsCheck(tempArr);
  };
  const submitData = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      setValidated(true);
    } else {
      event.preventDefault();

      if (subTabType === 'general') {
        editUserDetailFullinfo();
      } else if (subTabType === 'languages') {
        addLanguages();
      } else if (subTabType === 'links') {
        editUserDetail();
      }

      setValidated(false);
    }
  };
  const handle = event => {
    event.preventDefault();

    if (subTabType === 'general') {
      editUserDetailFullinfo();
    } else if (subTabType === 'languages') {
      addLanguages();
    } else if (subTabType === 'links') {
      editUserDetail();
    }
  };

  const handleSearch = (array, query) => {
    setLanguages(
      array.filter(el => {
        return el.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      }),
    );
  };

  const onChangeData = e => {
    const updatedValue = e.target.value.replace(/\s/g, '');
    setFirstName(updatedValue);
  };
  const onChangeDataLN = e => {
    const updatedValue = e.target.value.replace(/\s/g, '');
    setLastName(updatedValue);
  };
  const onChangeDataUN = e => {
    const updatedValue = e.target.value.replace(/\s/g, '');
    setUniqName(updatedValue);
  };

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  //// ========== EventBrite Integration ========== ////
  // const open = url => {
  //   // alert(url);
  //   setshowComponent('Outh');
  //   // return (
  //   //   <OauthPopup
  //   //     url={'https://www.eventbrite.com/oauth/authorize?response_type=token&client_id=MR5DTFGIF6V2PNHW7Z&redirect_uri=https://devapi.roiforpros.com/obtaining_access_code?id=1364,90,70,58,12,3,personal'}
  //   //     onCode={onCode}
  //   //     onClose={onClose}
  //   //   >
  //   //     <div>Click me to open a Popup</div>
  //   //   </OauthPopup>
  //   // );
  // };

  // const onSuccess = response => {}
  // const onFailure = response => {}

  //   const eventBriteLogin = () => {
  //       const userId = localStorage.getItem('id');
  //      // const apiUrl = `https://www.eventbrite.com/oauth/authorize?response_type=token&client_id=MR5DTFGIF6V2PNHW7Z&redirect_uri=${BACKEND_URL}/obtaining_access_code?id=${userId},${workSpaceIds.toString(',')}`;
  //       //  const apiUrl = `https://www.eventbrite.com/oauth/authorize?response_type=token&client_id=MR5DTFGIF6V2PNHW7Z&redirect_uri=${BACKEND_URL}obtaining_access_code?id=${userId},${workSpaceIds.toString(',')}`;
  //       // const origin = window.location.origin;
  //       // const apiUrl = `https://www.eventbrite.com/oauth/authorize?response_type=token&client_id=MR5DTFGIF6V2PNHW7Z&redirect_uri=${encodeURIComponent(`${origin}/obtaining_access_code?id=${userId},${workSpaceIds.toString(',')}`)}`;
  //       // open(apiUrl)

  // const apiUrl = `https://www.eventbrite.com/oauth/authorize?response_type=code&client_id=MR5DTFGIF6V2PNHW7Z&state=${userId},${workSpaceIds.toString(',')}`
  //

  //        const popupWidth = 700; // Set the desired width
  // const popupHeight = 600; // Set the desired height
  // const left = (window.innerWidth - popupWidth) / 2;
  // const top = (window.innerHeight - popupHeight) / 2;

  // // Open the popup window with the specified dimensions and URL
  // const popup = window.open(apiUrl, '', `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`);

  // const url = 'https://devapi.roiforpros.com/obtaining_access_code?'

  // // const checkPopup = setInterval(() => {
  // //   const popupUrl = popup.location.href

  // //   var spl = popupUrl.split("code")
  // //
  // //
  // //   if (spl[0] == url) {popup.close()
  // //       get_eventbrite_accounts()
  // //     }
  // //   if (!popup || !popup.closed) return;
  // //   clearInterval(checkPopup);
  // // }, 1000);

  //       //  window.open(
  //       //   apiUrl,
  //       //   'Popup', "width=600,height=500")

  //     //  window.open(apiUrl, 'OAuth login');

  //   };

  const eventBriteLogin = () => {
    const userId = localStorage.getItem('id');
    const apiUrl = `https://www.eventbrite.com/oauth/authorize?response_type=code&client_id=OTQSAZRMDWDVRSPIVD&state=${userId},
    )}`;

    const popupWidth = 700; // Set the desired width
    const popupHeight = 600; // Set the desired height
    const left = (window.innerWidth - popupWidth) / 2;
    const top = (window.innerHeight - popupHeight) / 2;
    // Open the popup window with the specified dimensions and URL
    const popup = window.open(
      apiUrl,
      '',
      `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`,
    );

    // Create a setInterval() loop to check if the popup window is still open
    const interval = setInterval(() => {
      if (!popup || popup.closed) {
        get_eventbrite_accounts();
        // Clear the setInterval() loop
        clearInterval(interval);

        // Perform any necessary cleanup tasks or update state in your React app
      }
    }, 1000);
  };

  //// ========== Google Calendar Integration ========== ////



  const login = useGoogleLogin({
    onSuccess: codeResponse => {
      //
      getAccessTokenFromAuthorizationCode(codeResponse.code);
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/calendar',
  });

  const getAccessTokenFromAuthorizationCode = authorizationCode => {
    const requestBody = {
      grant_type: 'authorization_code',
      client_id:
        '556170493308-2o3iobkftj6d5ppk7uh1vtc8c3fn84g4.apps.googleusercontent.com',
      client_secret: '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',
      redirect_uri: SITE_URL_FOR_API_Calendar,
      code: authorizationCode,
    };

    axios
      .post(GOOGLE_AUTH_TOKEN_ENDPOINT, requestBody)
      .then(response => {
        // commonApi.add_refresh_token({
        //   refresh_token: response.data.refresh_token,
        // });
        // alert('abcdefg')
        setAccessToken(response.data.access_token);
        accesTokenForGoogle = response.data.access_token;
        getUserProfile(response.data.access_token, response.data.refresh_token);
      })
      .catch(error => console.error(error));
  };

  const getUserProfile = (accessToken, refreshToken) => {
    // Define the Google People API endpoint
    const apiUrl =
      'https://people.googleapis.com/v1/people/me?personFields=emailAddresses';

    // Create the headers for the request
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    axios
      .get(apiUrl, {headers})
      .then(response => {
        // Extract the user's email from the response
        const userEmail = response.data.emailAddresses[0].value;

        // You can now use the user's email as needed in your application.
        getCalendars(userEmail, accessToken, refreshToken);
      })
      .catch(error => console.error(error));
  };

  const getCalendars = async (userEmail, accessToken, refreshToken) => {
    const ciphertext = CryptoJS.AES.encrypt(
      refreshToken,
      ENCRYPTION_KEY,
    ).toString();

    const apiUrl = `https://www.googleapis.com/calendar/v3/users/me/calendarList`;

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };

    return fetch(apiUrl, {headers})
      .then(response => response.json())
      .then(async data => {
        const filterData = data.items.filter(
          item =>
            item.accessRole === 'owner' &&
            !calendarData.some(
              dataItem => dataItem.google_calendar_id === item.id,
            ),
        );

        if (filterData.length > 0) {
          const body = {refresh_token: ciphertext, email: userEmail};
          await commonApi.add_refresh_token(body);

          if (filterData.length > 0) {
            let calendars = [];
            filterData.forEach(calendar => {
              const calendarData = {
                calendar_id: calendar.id,
                summary: calendar.summary,
                selected: calendar.selected,
                time_zone: calendar.timeZone,
                etag: JSON.parse(calendar.etag),
                access_role: calendar.accessRole,
                primary: calendar.primary ? calendar.primary : false,
                description: calendar.description ? calendar.description : '',
                email: userEmail,
              };
              calendars.push(calendarData);
            });
            setCalenderListForModel(calendars);
            setShowCustomModel('show-calender-model');

            return calendars;
          } else {
            return [];
          }
        } else {
          errorMessage('No more Calendars Found');
        }
      })
      .catch(error => {
        console.log(error);
        return [];
      });
  };

  const getEvents = (id, calendarId, timeMin, maxResults, accessToken) => {
    const apiUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?timeMin=${timeMin}&orderBy=startTime&singleEvents=true&maxResults=${maxResults}`;

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };

    fetch(apiUrl, {headers})
      .then(response => response.json())
      .then(async data => {
        if (data.items && data.items.length > 0) {
          const body = {calender_id: id, items: data.items};
          await commonApi.add_google_calenders_events(body);

          get_google_calenders();
          return data.items;
        } else {
          get_google_calenders();
          return [];
        }
        // get_google_calenders();
      })
      .catch(error => {
        console.log(error);
        get_google_calenders();
      });
  };
  // const getEventsData = (accessToken, refreshToken) => {
  //   const ciphertext = CryptoJS.AES.encrypt(
  //     refreshToken,
  //     ENCRYPTION_KEY,
  //   ).toString();

  //   const calendarId = 'primary';
  //   const timeMin = new Date().toISOString();
  //   const maxResults = 100;

  //   const apiUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?timeMin=${timeMin}&orderBy=startTime&singleEvents=true&maxResults=${maxResults}`;

  //   // Set up the request headers
  //   const headers = {
  //     Authorization: `Bearer ${accessToken}`,
  //     'Content-Type': 'application/json',
  //   };

  //   // Make the API request
  //   fetch(apiUrl, {headers})
  //     .then(response => {
  //       if (response.ok) {
  //         return response.json();
  //       }
  //       throw new Error('Failed to fetch events data');
  //     })
  //     .then(data => {
  //       console.log('Events data:', data);
  //       commonApi
  //         .add_refresh_token({
  //           refresh_token: ciphertext,
  //           email: data.summary,
  //         })
  //         .then(() => {
  //           getRefreshToken();
  //         });

  //       commonApi.add_google_calender({items: data.items});
  //     })
  //     .catch(error => {
  //       console.error('Error fetching events data:', error);
  //     });
  // };

  const getRefreshToken = () => {
    commonApi.get_refresh_token().then(response => {
      setallCalendars(response.refresh_token);
    });
  };

  //// ========== Google Calendar Integration ========== ////

  const logout = () => {
    let payload = {};
    commonApi
      .logout(payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          history.push('/');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const get_google_calenders = () => {
    commonApi
      .get_google_calenders({token: localStorage.getItem('token')})
      .then(response => {
        setCalendarData(response.all_calendars);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const get_outLook_calenders = () => {
    commonApi
      .get_outlook_calendars({token: localStorage.getItem('token')})
      .then(response => {
        setOutLookData(response.all_calendars);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const hanldeCalenderlist = item => {
    if (!modelId.includes(item.calendar_id)) {
      setModelId(prev => [...prev, item.calendar_id]);
      setModelItem(prev => [...prev, item]);
    } else {
      setModelId(previous => previous.filter(id => id !== item.calendar_id));
      setModelItem(previous =>
        previous.filter(miniItem => miniItem.calendar_id !== item.calendar_id),
      );
    }
  };

  const hanldeOutLookCalenderlist = item => {
    if (!modelId.includes(item.id)) {
      setModelId(prev => [...prev, item.id]);
      setModelItem(prev => [...prev, item]);
    } else {
      setModelId(previous => previous.filter(id => id !== item.id));
      setModelItem(previous =>
        previous.filter(miniItem => miniItem.id !== item.id),
      );
    }
  };

  const AddWorksapceIds = async () => {
    const body = {
      items: modelIdItem,
      workspace_ids: workSpaceIds.toString(','),
    };
    const response = await commonApi.add_google_calenders(body);

    const timeMin = new Date().toISOString();
    const maxResults = 100;
    response &&
      response.all_calenders_data.forEach(calendar => {
        getEvents(
          calendar.id,
          calendar.google_calendar_id,
          timeMin,
          maxResults,
          accesTokenForGoogle ? accesTokenForGoogle : accessToken,
        );
      });

    setShowCustomModel('');
    setModelId([]);
    setModelItem([]);
    // hitfunctionForLoop();

    //
  };

  const add_outlook_calendars = async () => {
    const body = {
      items: modelIdItem,
      workspace_ids: workSpaceIds.toString(','),
    };
    const response = await commonApi.add_outlook_calendars(body);

    if (response.status === 200) {
      successMessage(response.message);
      get_outLook_calenders()
    }
    // else{
    //   errorMessage(response.message)
    // }

    setShowCustomModel('');
    setModelId([]);
    setModelItem([]);
    // hitfunctionForLoop();

    //
  };

  const Add = async () => {
    const body = {
      items: modelIdItem,
      workspace_ids: workSpaceIds.toString(','),
    };
    const response = await commonApi.add_google_calenders(body);

    const timeMin = new Date().toISOString();
    const maxResults = 100;
    response &&
      response.all_calenders_data.forEach(calendar => {
        getEvents(
          calendar.id,
          calendar.google_calendar_id,
          timeMin,
          maxResults,
          accesTokenForGoogle ? accesTokenForGoogle : accessToken,
        );
      });

    setShowCustomModel('');
    setModelId([]);
    setModelItem([]);
    // hitfunctionForLoop();

    //
  };

  const deleteCalender = id => {
    const payload = {calendar_id: id};
    commonApi
      .delete_linked_calendars(payload)
      .then(response => {
        if (typeofCalender === 'google-calendar') {
          setCalendarData(previous => previous.filter(item => item.id !== id));
        } else if (typeofCalender === 'outlook') {
          setOutLookData(previous => previous.filter(item => item.id !== id));
        }

        // setCalendarData(filteredCalendars);
        successMessage(response.message);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getUserData = id => {
    var data = {user_id: localStorage.getItem('id')};
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          if (res.data) {
            localStorage.setItem('userName', res.data.unique_user_name);
            localStorage.setItem('email', res.data.email);
            localStorage.setItem('firstName', res.data.first_name);
            localStorage.setItem('lastName', res.data.last_name);
            setLastName(res.data.last_name);
            setFirstName(res.data.first_name);
            setEmail(res.data.email);
            setUniqName(res.data.unique_user_name);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const onMicrosoftAuth = async () => {
  //   try {
  //     // Await the loginPopup method
  //     const authResult = await instance.loginPopup(msalRequest);
  //     console.log(authResult, '--------->authResult');

  //     // Return the token or any other necessary information
  //     return authResult.accessToken; // or authResult.idToken depending on what you need
  //   } catch (error) {
  //     console.error('Login failed:', error);
  //     // Handle or return the error
  //     throw error; // or return null / undefined if you prefer
  //   }
  // };

  // const getMicrosoftToken = async () => {
  //   let account = sessionStorage.getItem('msalAccount');
  //   if (!account) {
  //       throw new Error('User info cleared from session. Please sign out and sign in again.');
  //   }

  //   try {
  //       const silentRequest = {
  //           scopes: msalRequest.scopes,
  //           account: msalClient.getAccountByUsername(account)
  //       };

  //       const silentResult = await msalClient.acquireTokenSilent(silentRequest);
  //       return silentResult.accessToken;
  //   } catch (silentError) {
  //       if (silentError instanceof msal.InteractionRequiredAuthError) {
  //           const interactiveResult = await msalClient.acquireTokenPopup(msalRequest);
  //           return interactiveResult.accessToken;
  //       } else {
  //           throw silentError;
  //       }
  //   }
  // };

  // const getMicrosoftCalendars = async () => {
  //   try {
  //     const token = await onMicrosoftAuth();

  //     console.log(token, '------>');
  //     if (token) {
  //       // Perform your fetch operation with the token
  //       // const calendarEvents = await fetchCalendars(token);
  //       // console.log('calendarEvents====', calendarEvents);
  //       // setEvents(calendarEvents);
  //     } else {
  //       console.error('No token received');
  //     }
  //   } catch (error) {
  //     console.error('Failed to get Microsoft calendars:', error);
  //   }
  // };

  

  return (
    <>
      {/* <div
        className="serach-filter-wrapper"
        // style={{paddingLeft: '50px', width: '43%'}}
        > */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        {!props.tabinformation && (
          <>
            <div className="event-tabs tabs-style-2 mb-0">
              <ul>
                <li>
                  <Button
                    className={subTabType === 'general' ? 'active' : ''}
                    onClick={() => {
                      setSubTabsType('general');
                      amplitudeEvent('VISIT_GENERAL_SETTINGS');
                      // setTypeofCalender('google-calendar')
                    }}>
                    General
                  </Button>
                </li>
                <li>
                  <Button
                    className={subTabType === 'languages' ? 'active' : ''}
                    onClick={() => {
                      setSubTabsType('languages');
                      getAllLanguages();
                      amplitudeEvent('VISIT_LANGUAGE_SETTINGS');
                      // setTypeofCalender('google-calendar')
                    }}>
                    Languages
                  </Button>
                </li>
                <li>
                  <Button
                    className={subTabType === 'links' ? 'active' : ''}
                    onClick={() => {
                      setSubTabsType('links');
                      getLinkForConsultSessionOppoertunity();
                      amplitudeEvent('VISIT_INTEGERATION_SETTINGS');
                      setTypeofCalender('google-calendar');
                      get_google_calenders();
                    }}>
                    Integrations
                  </Button>
                </li>
              </ul>
            </div>

            <div className="search-tabs-icons">
              <span
                className={`icon as-link ${showComponent ? 'active' : ''}`}
                onClick={() => {
                  setshowComponent('preferences');
                  amplitudeEvent('VISIT_PREFERENCES');
                }}>
                <i className="icon-Asset-1"></i>
              </span>
            </div>
          </>
        )}
      </div>
      <SlidingPanel hidePanel={() => props.onClose()} hideCloseicon={true}>
        <>
          {/* {showLoader && <LoaderSpinner />} */}
          {subTabType === 'general' && (
            <div
              className="send-refferal-form-wrapper mt-2"
              style={{width: '366px'}}>
              <Form noValidate validated={validated} onSubmit={submitData}>
                <Form.Group className="fieldset">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    required
                    autoComplete={'off'}
                    type="text"
                    value={firstName}
                    onChange={e => onChangeData(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    First name is required.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="fieldset">
                  <Form.Label>Last Name </Form.Label>
                  <Form.Control
                    required
                    autoComplete="off"
                    type="text"
                    value={lastName}
                    onChange={e => onChangeDataLN(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Last name is required.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="fieldset">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    required
                    autoComplete="off"
                    type="text"
                    pattern="[^\s ]+"
                    value={uniqName}
                    onChange={e => onChangeDataUN(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {uniqName === ''
                      ? 'Username is required.'
                      : 'Space not allowed.'}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="fieldset">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    required
                    type="phone"
                    value={localStorage.getItem('number')}
                    // onChange={handleName}
                    disabled
                  />

                  {/* <PhoneInput
                                    onlyCountries={ALLOWED_COUNTRY}
                                    country={"us"}
                                    value=""
                                    onChange={(phone) => {}}
                                    placeholder="xxx-xxx-xxxx"
                                /> */}
                </Form.Group>
                <Form.Group className="fieldset">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    autoComplete="off"
                    // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                    type="email"
                    value={email === 'null' ? '' : email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Invalid email.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="fieldset">
                  <span
                    className="use-my-contacts"
                    onClick={() => {
                      logout();
                      localStorage.clear();
                      dispatch(callDeepLink());
                    }}>
                    Log Out{' '}
                  </span>
                </Form.Group>
                <Form.Group className="fieldset">
                  <span className="use-my-contacts" onClick={handleShow}>
                    Delete Account
                  </span>
                </Form.Group>
                <div className="filter-btn-wrapper">
                  <div className="invite-btns text-left p-0">
                    <Button className="btn-dark" type="submit">
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          )}

          {subTabType === 'languages' && (
            <div
              className="language-wrapper primary_panel_content"
              style={{width: '366px', maxWidth: '366px'}}>
              <h2>Primary Language</h2>

              <span className="choosen-lang">
                {Object.entries(languageArray).length > 0 &&
                  languageArray.map((item, index) => (
                    <>{item.code === userLanguage ? item.name : <></>}</>
                  ))}
              </span>

              <div className="language-filter search-form">
                <Form.Group className="search-input">
                  <Form.Control
                    onChange={e => {
                      handleSearch(searchLanguages, e.target.value);
                      setSearch(e.target.value);
                    }}
                    // value={searchQry}
                    type="text"
                    placeholder="What are languages do you speak?"
                  />
                </Form.Group>
              </div>

              <div className="refferal-list-wrapper">
                <Scrollbars>
                  <Form
                    //onSubmit={handleSubmit}
                    className="">
                    <div className="languages-list-wrapper">
                      <ul>
                        {search === '' && (
                          <li
                            className={isCheckAll ? 'active' : ''}
                            onClick={handleSelectAll}>
                            {isCheckAll && <i className="fas fa-check" />}
                            All
                          </li>
                        )}
                        {Object.entries(languages).length > 0 ? (
                          Object.entries(languages).length > 0 &&
                          languages.map((item, index) => (
                            <li
                              className={isCheck.includes(item.id) && 'active'}
                              key={`languages-${index}`}
                              onClick={() => handleClick(item.id)}>
                              {item.name}
                              {isCheck.includes(item.id) && (
                                <i className="fas fa-check" />
                              )}
                            </li>
                          ))
                        ) : (
                          <p className="text-center">No records found</p>
                        )}
                      </ul>
                    </div>
                  </Form>
                </Scrollbars>

                {subTabType !== 'general' && subTabType !== 'links' && (
                  <div
                    className="filter-btn-wrapper bg_transparent bg_transparent_filter_btn text-center"
                    style={{bottom: '10px'}}>
                    <Button
                      className="btn-dark"
                      onClick={handle}
                      style={{marginBottom: '0px'}}>
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}

          {subTabType === 'links' && (
            <div className="Settings-Integration" style={{width: '366px'}}>
              {/* <Form.Group className="fieldset">
                            <div className="search-form">
                              <div className="select-wrapper">
                                <select
                                  className="form-control"
                                  onChange={e => {
                                    setItemType(e.target.value);
                                  }}>
                                  <option value="consult">Consult</option>
                                  <option value="event">Event</option>
                                  <option value="opportunity">
                                    Opportunity
                                  </option>
                                  <option value="session">Session</option>
                                  <option value="profile">Profile</option>
                                </select>
                              </div>
                            </div>
                          </Form.Group>

                          <Form.Group className="fieldset">
                            {itemType === 'event' || itemType === 'session' ? (
                              <>
                                <div className="search-form">
                                  <div className="select-wrapper">
                                    <select
                                      className="form-control"
                                      onChange={e => {
                                        setitemTypeSe(e.target.value);
                                      }}>
                                      {Object.entries(sessionCategories)
                                        .length > 0 &&
                                        sessionCategories.map((item, index) => (
                                          <option
                                            id={item.id}
                                            value={item.id}
                                            key={`sessionCategories-${index}`}>
                                            {item.ses_category_name}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {itemType === 'opportunity' ? (
                              <>
                                <div className="search-form">
                                  <div className="select-wrapper">
                                    <select
                                      className="form-control"
                                      onChange={e => {
                                        setitemTypeSe(e.target.value);
                                      }}>
                                      <option>All</option>
                                      {Object.entries(opportunity).length > 0 &&
                                        opportunity.map((item, index) => (
                                          <option
                                            id={item.id}
                                            value={item.id}
                                            key={`opportunity-${index}`}>
                                            {item.type_name}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {itemType === 'consult' ? (
                              <>
                                <div className="search-form">
                                  <div className="select-wrapper">
                                    <select
                                      className="form-control"
                                      onChange={e => {
                                        setitemTypeSe(e.target.value);
                                      }}>
                                      <option>All</option>
                                      {Object.entries(consult).length > 0 &&
                                        consult.map((item, index) => (
                                          <option
                                            id={item.id}
                                            value={item.id}
                                            key={`consult-${index}`}>
                                            {item.name}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            <div className="qr-img">
                              <img src={qrImage} alt="" />
                            </div>

                            <div className="qr-link">
                              www.tryingtobuild.com/
                              {localStorage.getItem('userName')}/{itemType}
                              {itemTypeSe && <>?type={itemTypeSe}</>}
                            </div>

                            <Form.Group className="fieldset">
                              <Form.Control
                                type="text"
                                value={uniqName}
                                onChange={e => {
                                  setUniqName(e.target.value);
                                }}
                              />
                            </Form.Group>
                          </Form.Group> */}
              {/* <div className="invite-persons-heading">
                            {subTabType === 'links' && (
                              <span
                                className="builder-text as-link"
                                onClick={() => {
                                  navigator.clipboard.writeText(link);
                                  successMessage(labels.copy_link[selected_lang]);
                                }}>
                                Copy Link
                              </span>
                            )}
                          </div> */}

              <div className="row w-100 fieldset">
                <Col md={11} sm={11} className="px-0">
                  {/* <Form.Group className="">
                    <div className="search-form">
                      <div className="select-wrapper">
                        <select
                          className="form-control"
                          onChange={e => {
                            setTypeofCalender(e.target.value);
                          }}>
                          <option value="google-calendar">
                            Google Calendar
                          </option>
                          <option value="event">EventBrite</option>
                        </select>
                      </div>
                    </div>
                  </Form.Group> */}

                  {Object.entries(dropdownValues).length > 0 && (
                    <CustomDropdown
                      setDropdownId={setTypeofCalender}
                      dropdownId={typeofCalender}
                      DropDownValues={dropdownValues}
                      itemName={['name']}
                      // className={'dropdown_font mb-3'}
                    />
                  )}
                </Col>
                <Col md={1} sm={1} className="d-flex align-items-center">
                  <div
                    // className="add-icon as-link"

                    className={
                      'add-icon as-link'
                      //   console.log('local or dev');
                      //   BACKEND_URL_Api = 'https://devapi.roiforpros.com/';
                      // } else if (host.includes('staging')) {
                      //   console.log('staging enviorment');
                      //   BACKEND_URL_Api = 'https://stagingapi.roiforpros.com/';
                      // } else {
                      //   console.log('live enviorment');
                      //   BACKEND_URL_Api = 'https://liveapi.roiforpros.com/';
                      // }
                      // typeofCalender == 'event'
                      //   ? 'disabled-Class'
                      //   : 'add-icon as-link'
                    }
                    onClick={() => {
                      if (typeofCalender === 'google-calendar') {
                        console.log('SITE_URL_FOR_API', SITE_URL_FOR_API);
                        login();
                      }
                      if (typeofCalender === 'event') {
                        // setshowComponent('persnal-model');
                        eventBriteLogin();
                      }
                    }}>
                    <i class="fa-solid fa-plus"></i>
                  </div>
                </Col>
              </div>
              {typeofCalender === 'google-calendar' && (
                <div className="integration-list">
                  {calendarData && Object.entries(calendarData).length > 0 ? (
                    calendarData.map((item, index) => (
                      <div className="fieldset card as-link row" key={index}>
                        <Col md={10} sm={10} className="p-0">
                          <h2 className="title mb-1">{item.summary}</h2>
                          <p>{item.description} </p>
                        </Col>
                        <Col md={2} sm={2} className="p-0">
                          <div
                            className="add-icon trash-icon as-link text-right"
                            onClick={() => {
                              deleteCalender(item.id);
                            }}>
                            <i class="fa-solid fa-trash-can"></i>
                          </div>
                        </Col>
                      </div>
                    ))
                  ) : (
                    <div className="no-results-wrapper no-appointments mt-5">
                      <div className="img-bg-wrapper">
                        <img src={NoGoogleCalendar} alt="" />
                      </div>
                      <div className="no-results-content">
                        <p className="noRecord-title">No Calendars Added</p>
                        <p className="noRecord-desc">
                          Connect with your Google Calendar to identify
                          scheduling conflicts
                        </p>

                        <div className="btn-wrapper mt-3">
                          <Button
                            className={'btn btn-dark'}
                            onClick={() => {
                              login();
                            }}>
                            Add Google Calendar
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {typeofCalender === 'event' && (
                <div className="integration-list">
                  {eventBriteList &&
                  Object.entries(eventBriteList).length > 0 ? (
                    eventBriteList.map((item, index) => (
                      <div className="fieldset card as-link row" key={index}>
                        <Col md={10} sm={10} className="p-0">
                          <h2 className="title mb-1">{item.name}</h2>
                          <p>{item.email} </p>
                        </Col>
                        <Col md={2} sm={2} className="p-0">
                          <div
                            className="add-icon trash-icon as-link text-right"
                            onClick={() => {
                              delete_eventbrite_accounts(item.id);
                            }}>
                            <i class="fa-solid fa-trash-can"></i>
                          </div>
                        </Col>
                      </div>
                    ))
                  ) : (
                    <div className="no-results-wrapper no-appointments mt-5">
                      <div className="img-bg-wrapper">
                        <img src={NoEventBrite} alt="" />
                      </div>
                      <div className="no-results-content">
                        <p className="noRecord-title">
                          Eventbrite Not Connected
                        </p>
                        <p className="noRecord-desc">
                          Connect with Eventbrite to keep your data in sync
                        </p>
                        {/* {!host.includes('localhost') &&
                                    !host.includes('dev') &&
                                    !host.includes('staging') && (
                                      <div className="btn-wrapper mt-3">
                                        <Button
                                          className={'btn btn-dark'}
                                          onClick={() => {
                                            eventBriteLogin();
                                          }}>
                                          Add Account
                                        </Button>
                                      </div>
                                    )} */}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* {typeofCalender === 'outlook' && (
                <div className="integration-list">
                  {outLookData && Object.entries(outLookData).length > 0 ? (
                    outLookData.map((item, index) => (
                      <div className="fieldset card as-link row" key={index}>
                        <Col md={10} sm={10} className="p-0">
                          <h2 className="title mb-1">{item.summary}</h2>
                         
                        </Col>
                        <Col md={2} sm={2} className="p-0">
                          <div
                            className="add-icon trash-icon as-link text-right"
                            onClick={() => {
                              deleteCalender(item.id);
                            }}>
                            <i class="fa-solid fa-trash-can"></i>
                          </div>
                        </Col>
                      </div>
                    ))
                  ) : (
                    <div className="no-results-wrapper no-appointments mt-5">
                      <div className="img-bg-wrapper">
                        <img src={NoGoogleCalendar} alt="" />
                      </div>
                      <div className="no-results-content">
                        <p className="noRecord-title">No Calendars Added</p>
                        <p className="noRecord-desc">
                          Connect with your Microsoft Calendar to identify
                          scheduling conflicts
                        </p>

                        <div className="btn-wrapper mt-3">
                          <Button
                            className={'btn btn-dark'}
                            onClick={() => {
                             
                              fetchCalendars();
                            }}>
                            Add Microsoft Calendar
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )} */}

{typeofCalender === 'outlook' && <OutlookCalendar outlookClenderData={outLookData}
              deleteCalender={deleteCalender}
              setOutLookListForModel={setOutLookListForModel}
              setShowCustomModel={setShowCustomModel}
               />}

              {/* <div className="settign-integ-btns">
                          {subTabType === 'links' && (
                            <>
                              <Button className="btn-dark">
                                Configure Spotify
                              </Button>

                              <Button
                                className="btn-dark"
                                onClick={() => login()}>
                                {' '}
                                Add Google Calendar
                              </Button>
                              <Button
                                className="btn-dark"
                                onClick={() => get_google_calenders()}>
                                {' '}
                                Add Google Calendar 2
                              </Button>
                            </>
                          )}
                        </div> */}
              {/* {allCalendars &&
                          Object.entries(allCalendars).length > 0 &&
                          subTabType === 'links' &&
                          allCalendars.map(item => {
                            return (
                              <div
                                key={item.id}
                                className="opportunity-item card">
                                {item.email}
                              </div>
                            );
                          })} */}
            </div>
          )}
        </>
      </SlidingPanel>
      {/* </div> */}

      {showCustomModel === 'show-calender-model' &&
        calenderListForModel.length > 0 && (
          <CustomModal
            style={{height: '100%'}}
            onClose={() => setShowCustomModel('')}>
            {/* {showLoader && <LoaderModal show={showLoader} />} */}

            <div className="serach-filter-wrapper">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="headings m-0">
                  <h2 className="m-0">Selected Desired Calendars</h2>
                </div>

                <div className="sidebar-heading-wrapper mt-0 text-right">
                  <div className="ticket-icons-options">
                    <ul>
                      <li
                        className={'active'}
                        onClick={() => {
                          setshowComponent('persnal-model');
                        }}>
                        <PersnalSvg fill={'white'} />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="filter-wrapper">
                <div className="filter-category-list">
                  <ul>
                    {Object.entries(calenderListForModel).length > 0 &&
                      calenderListForModel.map((item, index) => (
                        <li
                          key={`sessionC-${index}`}
                          //  className={
                          //   //  props.isCheck.includes(item.id) ? 'active' : ''
                          //  }
                        >
                          <Form.Label>
                            {item.summary}
                            <input
                              type="checkbox"
                              id={item.calendar_id}
                              value={item.calendar_id}
                              onChange={() => {
                                hanldeCalenderlist(item);
                              }}
                              checked={modelId.includes(item.calendar_id)}
                            />
                            <span />
                          </Form.Label>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              {/* </Scrollbars> */}
              <div className="filter-btn-wrapper pt-3">
                <Button
                  className="btn-dark"
                  type="submit"
                  onClick={() => {
                    if (modelIdItem.length > 0) {
                      AddWorksapceIds();
                    } else {
                      errorMessage('One workspace should be selected.');
                    }
                  }}>
                  Select
                </Button>
              </div>
            </div>
          </CustomModal>
        )}

      {showCustomModel === 'show-outlook-calender-model' &&
        outLookListForModel.length > 0 && (
          <CustomModal
            style={{height: '100%'}}
            onClose={() => setShowCustomModel('')}>
            {/* {showLoader && <LoaderModal show={showLoader} />} */}

            <div className="serach-filter-wrapper">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="headings m-0">
                  <h2 className="m-0">Selected Desired Calendars</h2>
                </div>

                {/* <div className="sidebar-heading-wrapper mt-0 text-right">
                  <div className="ticket-icons-options">
                    <ul>
                      <li
                        className={'active'}
                        onClick={() => {
                          setshowComponent('persnal-model');
                        }}>
                        <PersnalSvg fill={'white'} />
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>

              <div className="filter-wrapper">
                <div className="filter-category-list">
                  <ul>
                    {Object.entries(outLookListForModel).length > 0 &&
                      outLookListForModel.map((item, index) => (
                        <li
                          key={`sessionC-${index}`}
                          //  className={
                          //   //  props.isCheck.includes(item.id) ? 'active' : ''
                          //  }
                        >
                          <Form.Label>
                            {item.name}
                            <input
                              type="checkbox"
                              id={item.id}
                              value={item.id}
                              onChange={() => {
                                hanldeOutLookCalenderlist(item);
                              }}
                              checked={modelId.includes(item.id)}
                            />
                            <span />
                          </Form.Label>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              {/* </Scrollbars> */}
              <div className="filter-btn-wrapper pt-3">
                <Button
                  className="btn-dark"
                  type="submit"
                  onClick={() => {
                    if (modelIdItem.length > 0) {
                      add_outlook_calendars();
                    } else {
                      errorMessage('One workspace should be selected.');
                    }
                  }}>
                  Select
                </Button>
              </div>
            </div>
          </CustomModal>
        )}
      {show && (
        <CustomModal onClose={() => handleClose()}>
          <div className="leave-session-content">
            <p>Are you sure you want to delete your account?</p>
            <Button
              className="btn-dark"
              onClick={() => {
                submitFeedback();

                handleClose();
              }}>
              Yes
            </Button>
            <Button className="btn-dark" onClick={handleClose}>
              No
            </Button>
          </div>
        </CustomModal>
      )}

      {showComponent === 'WorkSpace' && (
        <CreateWorkSpace
          onClose={() => {
            setshowComponent('');
          }}
        />
      )}

      {showComponent === 'persnal-model' && (
        <PersonalCreateModel
          type="eventbrite"
          list={list}
          setWorkSpaceIds={setWorkSpaceIds}
          workSpaceIds={workSpaceIds}
          onClose={() => {
            setshowComponent('');
            // setTopIcons(false);
          }}
          // Temporary states for remove warning
          eventBriteLogin={eventBriteLogin}
          setitemTypeSe={setitemTypeSe}
          setItemType={setItemType}
          allCalendars={allCalendars}
          showHiddenPanel={showHiddenPanel}
        />
      )}
    </>
  );
};
export default Settings;
